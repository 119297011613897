import { useContext, useState } from "react";
import classes from "./AdminPanel.module.css";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import LogoutIcon from "@mui/icons-material/Logout";
import { Link } from "react-router-dom";
import Clients from "./Clients/Clients List/Clients";
import Logo from './../../assets/Logo.png'
  

import "./AdminPanel.module.css";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
   
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const AdminPanel = (props) => {
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const [menuOption, setMenuOption] = useState("clientslist");

  const overlay = classes.overlay;

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: "flex" }}>
     <CssBaseline />
      <AppBar position="absolute" open={open} className={classes.sidebartop}>
        <Toolbar>

          {!open && (
            <Typography variant="h6" noWrap component="div"  >
              <img style={{ width: "65%", marginLeft:'35%' }} src={Logo}/>
            </Typography>
          )}
          
            

        </Toolbar>
      </AppBar>
  
      <Drawer variant="permanent" open={open}  >
        <DrawerHeader className={classes.cssssss}>
        
        <Typography variant="h6" noWrap component="div">
        {open && <img style={{ width: "65%"}} src={Logo} alt="Logo" />}
        </Typography>
          <IconButton onClick={handleDrawerClose}></IconButton>
        </DrawerHeader>
       
        <List className={classes.sidebartop1}>
        <IconButton 
      color="inherit"
      
      aria-label="toggle drawer"
      onClick={open ? handleDrawerClose : handleDrawerOpen}
      edge="end"
      sx={{
        marginLeft: 4, 
      }}
    >
      {open ? (
        <ChevronLeftIcon  className={classes.closeicon}/>
      ) : (
        <ChevronRightIcon className={classes.closeicon}/>
      )}
    </IconButton>
          <div
            onClick={() => {
              setMenuOption("clientslist");
            }}
            className={menuOption === "clientslist" ? `${classes.overlay}` : ""}
          >
            <ListItem
              disablePadding
              sx={{ display: "block" }}
              className={
                menuOption === "clientslist" ? `${classes.overlay1}` : ""
              }
            >
              <ListItemButton
                sx={{
                  minHeight: 48,

                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <AccountBoxIcon className={classes.icons} />
                </ListItemIcon>
                <ListItemText
                  primary="Clients List"
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          </div>
        </List>
        
        <List className={classes.sidebar}>
          <div
            onClick={() => {
              setMenuOption("settings");
            }}
            className={menuOption === "settings" ? `${classes.overlay}` : ""}
          >
            <ListItem
              disablePadding
              sx={{ display: "block" }}
              className={menuOption === "settings" ? `${classes.overlay1}` : ""}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <SettingsSuggestIcon className={classes.icons} />
                </ListItemIcon>
                <ListItemText
                  primary="Settings"
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          </div>
          <Link to="/login">
            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,

                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <LogoutIcon className={classes.icons} />
                </ListItemIcon>
                <ListItemText primary="Logout" sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          </Link>
        </List>
      </Drawer>
      <Box sx={{ flexGrow: 1 }}>
        {menuOption === "clientslist" && <Clients />}
      </Box>
    </Box>
  );
};
export default AdminPanel;
