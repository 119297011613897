
import React, { useState, useContext } from "react";
import classes from "../Items/Items.module.css";
import AuthContext from '../../../Common/auth-context';

import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";

import TextField from "@mui/material/TextField";
import StoresSearchBox from "./Stores Search/StoresSearch";

export const store = [
{ id: 1, logo: 1, name: "Kitkat", type:"Restaurant", CreationDate: "13-2-2018",},
{ id: 2, logo: 1, name: "Galaxy", type:"Café", CreationDate: "20-10-2016",},
{ id: 3, logo: 1, name: "Crepe", type:"Restaurant", CreationDate: "29-2-2020",},
{ id: 4, logo: 1, name: "Store", type:"Food truck", CreationDate: "17-11-2010",},
];



const headCells = [
    {
    id: "logo",
    label: "",
  },
  {
    id: "name",
    label: "Name",
  },
  
  {
    id: "type",
    label: "Type",
  },
  {
    id: "CreationDate",
    label: "Creation Date",
  },
];

function EnhancedTableHead(props) {
  

  return (
    <TableHead>
      <TableRow className={classes.headingRow}>
        {headCells.map((headCell) => (
          <TableCell className={classes.headingRow} key={headCell.id}>
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;
  const authCtx = useContext(AuthContext);

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}        
      className={classes.tableHeading}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Stores
        </Typography>
      )}

      <StoresSearchBox data={store} />

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
      <AddCircleIcon onClick={()=>authCtx.setMultiStore(false)}/>
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const MultiStores = () => {  
  const authCtx = useContext(AuthContext);
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - store.length) : 0;


  return (
          <div className={classes.itemsContainer}>
        <div className={classes.itemsHeading}>
            <p className={classes.itemHead}>Stores</p>
            <p>Manage your Stores</p>
        </div>
    <div className={classes.itemsTableContainer}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              rowCount={store.length}
            />
            <TableBody>
                    {authCtx.storesfilter
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((items) => {
                          return (
                            <TableRow
                              onDoubleClick={()=>authCtx.setMultiStore(false)}
                              key={items.id}
                              className={classes.itemRow}
                            >
                              <TableCell>{items.logo}</TableCell>
                              <TableCell>{items.name}</TableCell>
                              <TableCell>{items.type}</TableCell>
                              <TableCell>{items.CreationDate}</TableCell>
                            </TableRow>
                          );
                      })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[2, 5, 10]}
          component="div"
          count={store.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
     
    </div>
    </div>

  )
}

export default MultiStores


