import React, {useState,useEffect,useReducer,useContext,useRef} from 'react';
import iconHolder from "../../assets/iconHolder.svg";
import imgHolder from "../../assets/menu.png";
import Button from '@mui/material/Button';
import AuthContext from '../../Common/auth-context';
import classess from './Login.module.css';
import { Link, useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Navbar from '../Website/common/Header Section/Navbar';
import Footer from '../Website/common/Footer Section/Footer';






const emailReducer = (state, action) => {
  if (action.type === 'USER_INPUT') {
    return { value: action.val, isValid: action.val.includes('@') };
  }
  if (action.type === 'INPUT_BLUR') {
    return { value: state.value, isValid: state.value.includes('@') };
  }
  return { value: '', isValid: false };
};



const passwordReducer = (state, action) => {
  if (action.type === 'USER_INPUT') {
    return { value: action.val, isValid: action.val.trim().length > 6 };
  }
  if (action.type === 'INPUT_BLUR') {
    return { value: state.value, isValid: state.value.trim().length > 6 };
  }
  return { value: '', isValid: false };
};

const users = [
  { id: 1, userName: "client", password: "123456789", type:"client"},
  { id: 2, userName: "admin", password: "123456789", type:"admin"},
  ];
const Login = (props) => {

  const [option1Checked, setOption1Checked] = useState(false);
  const handleOption1Change = () => {
    setOption1Checked(!option1Checked);
  };

  const navigate = useNavigate();

    const [enteredUserName, setEnteredUserName] = useState('');
    const [userNameIsValid, setUserNameIsValid] = useState(true);
    const [enteredPass, setEnteredPass] = useState('');
    const [passIsValid, setPassIsValid] = useState(true);
    const [formIsValid, setFormIsValid] = useState(false);
    const [errorUser, setErrorUser] = useState(false);
    const [errorPass, setErrorPass] = useState(false);

    const [emailState, dispatchEmail] = useReducer(emailReducer, {
        value: '',
        isValid: null,
      });
  
    const [passwordState, dispatchPassword] = useReducer(passwordReducer, {
      value: '',
      isValid: null,
    });

  const authCtx = useContext(AuthContext);

  const { isValid: emailIsValid } = emailState;
  const { isValid: passwordIsValid } = passwordState;

  useEffect(() => {
    const identifier = setTimeout(() => {
      console.log('Checking form validity!');
      setFormIsValid(emailIsValid && passwordIsValid);
    }, 500);

    return () => {
      console.log('CLEANUP');
      clearTimeout(identifier);
    };
  }, [emailIsValid, passwordIsValid]);

  const emailChangeHandler = (event) => {
    dispatchEmail({ type: 'USER_INPUT', val: event.target.value });
    setEnteredUserName(event.target.value.trim());

  };

  const passwordChangeHandler = (event) => {
     dispatchPassword({ type: 'USER_INPUT', val: event.target.value });
   
     setEnteredPass(event.target.value.trim());
     
  };

  const validateEmailHandler = (event) => {
           
    dispatchEmail({ type: 'INPUT_BLUR' });
 
    if(enteredUserName.length > 3){
      setUserNameIsValid(true)
    }else{
      setUserNameIsValid(false)
    }
  };

  const validatePasswordHandler = () => {
    
    dispatchPassword({ type: 'INPUT_BLUR' });
    if(enteredPass.length > 6){
      setPassIsValid(true)
    }else{
      setPassIsValid(false)
    }
  };

  const submitHandler = (event) => {
    event.preventDefault();
    for (let i = 0; i < users.length; i++) {
      const data = users[i];
      if(passIsValid && userNameIsValid){
      if (data.userName === emailState.value) {
        if ( data.password === passwordState.value){
        if (data.type === "admin"){
               console.log("adminnnnn");
               navigate('/adminpanel'); 
             } else if (data.type === "client"){
               console.log("client");
                navigate('/home'); 
              }
              setErrorUser(false);
              setErrorPass(false)
              break;
            } else {
                    setErrorUser(false);
                    setErrorPass(true);
                    break;
                   }
      }
      setErrorUser(true); 
    }
  }
  };
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorUser(false);
  };

  return (
  <>
    <Navbar/>
    <div className={classess.bigcontainer}>
    <div className={classess.login__container}>
        <Snackbar
          open={errorUser}
          autoHideDuration={6000}
          
          sx={{ top: { xs: 0, sm: -500 },
              left: { xs: 0, sm: 650 } }}
        >
      <Alert  onClose={handleClose} severity="error" autoHideDuration={6000} sx={{ width: '100%' }}>
        user Name is not found!<br/>Please try again.
        </Alert>
      </Snackbar>
      <Snackbar
          open={errorPass}
          autoHideDuration={6000}
          
          sx={{ top: { xs: 0, sm: -500 },
              left: { xs: 0, sm: 650 } }}
        >
      <Alert  onClose={handleClose} severity="error" autoHideDuration={6000} sx={{ width: '100%' }}>
        Password is incorrect !<br/>Please try again.
        </Alert>
      </Snackbar>
      <div className={classess.title}>Welcome back!</div>
    <div className={classess.login}>
      <form onSubmit={submitHandler}>
        <div className={classess.formm}>
          <label htmlFor="standard-adornment-password">UserName</label>
            <input
            id="user-name"         
             isValid={emailIsValid}
          label="User Name"
          type="search"
          variant="standard" 
          value={emailState.value}
          onChange={emailChangeHandler}
          onBlur={validateEmailHandler}
          className={classess.inputss}
        />
         {!userNameIsValid && <p className={classess.invalid}>*Please enter valid user name</p>} 
       
          <label  htmlFor="standard-adornment-password">Password</label>
          <input
            id="standard-adornment-password"
            isValid={passwordIsValid}
            value={passwordState.value}
            className={classess.passwordinput}
            onChange={passwordChangeHandler}
            onBlur={validatePasswordHandler}
            type={showPassword ? 'text' : 'password'}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            } 
          />
      { !passIsValid && <p className={classess.invalid}>*Please enter valid password</p>}
        <div className={classess.actions}>
       
      <div className={classess.checkbox}>
      <input 
          type="checkbox"
          checked={option1Checked}
          onChange={handleOption1Change}
        /> Remember me </div><p className={classess.forget__pass}>Forgot your password?</p> 
        
          
          <Button variant="contained" className={classess.buttons} type="submit">Login</Button>
          
          
          <p> Don’t you have an account? <Link to='/register'> <span className={classess.request}>Register Now</span></Link></p>
        </div></div>
      </form>
      {/* <div className={classess.terms_privacy} >
      <p className={classess.terms}>Terms and Conditions</p>
      <p className={classess.privacy}>Privacy</p></div>  */}
    </div>
    </div></div>
    <Footer/>
    </>
  );
};

export default Login;
