import * as React from "react";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import Box from '@mui/material/Box';

import classes from "./Profile.module.css";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider/LocalizationProvider";
import AuthContext from "../../../Common/auth-context";

var today = new Date(),
date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();


const Subscription = () => {
  const [Subscription, setSubscription] = React.useState(date);
  const authCtx = React.useContext(AuthContext);

  var expiry = new Date(authCtx.accountData.expiryDate);

  const endDate = (expiry.getTime() - today.getTime());
  const minute = (1000 * 60);
  const hour = (minute * 60);
  const dayy = (hour * 24);

  return (
    
        <div className={classes.subs_dates}>
         
        {/* <Box component="span" sx={{ p: 2, border: '1px dashed grey' }}>
        Remaining Days: {Math.round(endDate/dayy)+1}
    </Box> */}
          </div>

  );
};

export default Subscription;
