import React, { useContext, useState } from "react";
import TextField from "@mui/material/TextField";
import Card from "../../../Common/UI/Card/Card";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AuthContext from "../../../Common/auth-context";

import classes from "./Profile.module.css";
import Switch from "@mui/material/Switch";

const label = { inputProps: { "aria-label": "Switch demo" } };

const CompanySettings = () => {
  const authCtx = useContext(AuthContext);
  
  return (  
        <div className={classes.inputs_container_location}>
            <div className={classes.info}>
            <input type="text" placeholder="Multi Store Limit" className={classes.inputext}/>
              <p>{authCtx.companyData.multiStoreCount}</p>
            </div>
            <div className={classes.info}>
            <input type="text" placeholder="Multi Location Limit" className={classes.inputext}/>
              <p>{authCtx.companyData.multiLocationCount}</p>
            </div>
            <div className={classes.info}>
            <input type="text" placeholder="Menu Categories Limit" className={classes.inputext}/>
              <p>{authCtx.companyData.menuCategoriesCount}</p>
            </div>
            <div className={classes.info}>
            <input type="text" placeholder="Item Categories Limit" className={classes.inputext}/>
              <p>{authCtx.companyData.itemCategoriesCount}</p>
            </div>
            <div className={classes.info}>
            <input type="text" placeholder="Item Limit" className={classes.inputext}/>
              <p>{authCtx.companyData.itemCount}</p>
            </div>
        </div>
  );
};

export default CompanySettings;
