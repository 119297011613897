import React, { useState, useEffect, useContext } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

 import './App.css';
import AuthContext from './Common/auth-context';
import './App.css';
import Login from './Components/Login/Login';
import SideBar from './Components/Client Panel/SideBar/SideBar';
import Cards from './Components/Menu-Demo/Cards/Cards';
import AdminPanel from './Components/Admin Panel/AdminPanel';
import Menu from './Components/Menu-Demo/Menu';
import Landingpage from './Components/Website/Landing Page/Landingpage';
import Contactus from './Components/Website/Contact Us Section/Contactus';
import Register from './Components/Website/Register Page/Register';
function App() {
  const ctx = useContext(AuthContext);
  const [isopen, setIsopen] = useState(false)

  const toggle = () => {
    setIsopen(!isopen);
  }

  return (
       <Routes>
      <Route path='/' element={<Landingpage isOpen={isopen} toggle={toggle}/>}/>
      <Route path='/login' element={<Login />} />
      <Route path='/adminpanel' element={<AdminPanel />} />
      <Route path='/home' element={<SideBar />}/>
      <Route path="*" element={<Navigate to="/" replace />}/>
      <Route path='/menu' element={<Menu />}/>
      <Route path='/menu/cards' element={<Cards/>}/>
      <Route path='/contactus' element={<Contactus/>}/>
      <Route path='/register' element={<Register toggle={toggle}/>}/>
    </Routes>
  );
}

export default App;

// function App() {
//   const ctx = useContext(AuthContext);
//   const getTheme = ()=>{
//     return JSON.parse(localStorage.getItem("theme")) || false
//   }


// const [theme, setTheme] = useState(getTheme());
// useEffect (() => {
//   localStorage.setItem("theme", JSON.stringify(theme))
// }, [theme]
// )
//   return (
//     <div className={theme ? "theme-dark": ""}>
//       <main>
//            {/* {!ctx.isLoggedIn && <Home />} */}
//         {ctx.isLoggedIn && <Login />}
//         <div className= "content-bg-color main-content">
//        {/* <h1>Dark Mode is </h1>
//                       <input type="checkbox" onChange={() => setTheme(!theme)} checked={theme ? "true": ""} /> */}

//     </div>
//       </main>
//       </div>
//   );
// }

// export default App;