import React, { useState, useEffect, useContext } from "react";
import SearchIcon from "@mui/icons-material/Search";

import classes from "./StoresSearch.module.css";
import AuthContext from "../../../../Common/auth-context";

const StoresSearchBox = (props) => {
  const { data, cat } = props;
  const [storesname, setStoresname] = useState("");
  // const [filteredData, setFilteredData] = useState(data);
  const authCtx = useContext(AuthContext);

  useEffect(() => {
    const results = 
    data.filter((item) => item.name.toLowerCase().includes(storesname.toLowerCase()))
    authCtx.setStoresfiltered(results);
  }, [storesname, data]);

  return (
    <div>
      <form className={classes.form}>
        <input
          type="text"
          value={storesname}
          onChange={(event) => setStoresname(event.target.value)}
          placeholder="Search..."
        />
        <button type="submit">
          <SearchIcon />
        </button>
      </form>
      {/* <ul>
        {Clientsname && filteredData.map((item) => (
          <li key={item.id}>{item.client_name}</li>
        ))}
      </ul> */}
    </div>
  );
};

export default StoresSearchBox;
