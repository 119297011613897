import React, { useState, useEffect, useContext } from "react";
import SearchIcon from "@mui/icons-material/Search";

import classes from "./ClientsSearch.module.css";
import AuthContext from "../../../../Common/auth-context";

const ClientSearchBox = (props) => {
  const { data } = props;
  const [Clientsname, setClientsname] = useState("");
  // const [filteredData, setFilteredData] = useState(data);
  const authCtx = useContext(AuthContext);

  useEffect(() => {
    const results = data.filter(
      (item) =>
        item.client_name.toLowerCase().includes(Clientsname.toLowerCase()) ||
        item.store_name.toLowerCase().includes(Clientsname.toLowerCase()) ||
        item.location.toLowerCase().includes(Clientsname.toLowerCase())
    );
    authCtx.setClientfiltered(results);
  }, [Clientsname, data]);

  return (
    <div>
      <form className={classes.form} action="action_page.php">
        <input
          type="text"
          value={Clientsname}
          onChange={(event) => setClientsname(event.target.value)}
          placeholder="Search..."
        />
        <button type="submit">
          <SearchIcon />
        </button>
      </form>
      {/* <ul>
        {Clientsname && filteredData.map((item) => (
          <li key={item.id}>{item.client_name}</li>
        ))}
      </ul> */}
    </div>
  );
};

export default ClientSearchBox;
