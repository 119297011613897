import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";

import classes from "./Clients.module.css";
import AddModal from "../Add Edit Clients/Modal";
import AuthContext from "../../../../Common/auth-context";
import ClientSearchBox from "../Client Search/ClientsSearch";

export function EnhancedTableToolbar(props) {
  const {
    array,
    numSelected,
    store_name,
    client_name,
    phone,
    address,
    selected,
    handleClose,
  } = props;
  const authCtx = React.useContext(AuthContext);

  const DeleteHandler = () => {
    const newarray = store_name.filter((element) => element.id !== selected);

    console.log(client_name);
    console.log(selected);
  };

  return (
    <Toolbar
      className={classes.tablehead}
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {/* {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : ( */}
      <Typography
        sx={{ flex: "1 1 100%" }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        Clients Information
      </Typography>
      {/* )} */}

      {/* {numSelected > 0 ? (
        <Tooltip title="Delete" onClick={DeleteHandler}>
          <IconButton className={classes.icons}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : ( */}
      <>
        <ClientSearchBox data={array} />
        <Tooltip title="Filter list">
          <IconButton className={classes.icons}>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
        <IconButton
          title="Add Clients"
          className={classes.icons}
          onClick={() => {
            authCtx.setopenmodal(true);
            authCtx.setclienttitle("Add Client");
            authCtx.setCompanyData({});
            authCtx.setClientyData({});
            authCtx.setAccountData({});
            authCtx.seteditdata(false)
            authCtx.setallClientData({phonenumber:"+961"});
          }}
        >
          <AddCircleRoundedIcon />
        </IconButton>
        <AddModal
          handleClose={handleClose}
          store_name={store_name}
          client_name={client_name}
          address={address}
          phone={phone}
        />
      </>
      {/* )} */}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
