import React, { useState, useEffect, useContext } from "react";
import SearchIcon from "@mui/icons-material/Search";

import classes from "./CategoriesSearch.module.css";
import AuthContext from "../../../../Common/auth-context";

const CategoriesSearchBox = (props) => {
  const { data } = props;
  const [catsname, setCatsname] = useState("");
  // const [filteredData, setFilteredData] = useState(data);
  const authCtx = useContext(AuthContext);

  useEffect(() => {
    const results = 
    data.filter((item) => item.name.toLowerCase().includes(catsname.toLowerCase()))
    authCtx.setCatsfiltered(results);
  }, [catsname, data]);

  return (
    <div>
      <form className={classes.form}>
        <input
          type="text"
          value={catsname}
          onChange={(event) => setCatsname(event.target.value)}
          placeholder="Search..."
        />
        <button type="submit">
          <SearchIcon />
        </button>
      </form>
      {/* <ul>
        {Clientsname && filteredData.map((item) => (
          <li key={item.id}>{item.client_name}</li>
        ))}
      </ul> */}
    </div>
  );
};

export default CategoriesSearchBox;
