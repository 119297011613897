import { useEffect, useState } from "react";
import QRCode from 'react-qr-code'
import classes from './QRCode.module.css';

const WifiQRCode = () => {  
    const [qrText, setQrText] = useState("");
    const [authentication, setAuthentication] = useState('WPA2');
    const [name, setName] = useState('Metariba');
    const [password, setPassword] = useState('m3t@r!b@');
    const [hidden, setHidden] = useState(false);
            useEffect(() => {
                setQrText(`WIFI:T:${authentication};S:${name};${authentication !== 'nopass' ? `P:${password};` : ''}H:${hidden};`);
            },[]);

  return (
    <div className={classes.qrWifiContainer}>
      	<h1>Wifi QR Code</h1>
      	{qrText.length > 0 && <QRCode value={qrText} className={classes.qrWifi}/>}
      </div>
  )
}

export default WifiQRCode

