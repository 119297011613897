import React, { useContext, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Card from "../../../../Common/UI/Card/Card";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MyContext from "../../../../Common/auth-context";

import classes from "./Modal.module.css";
import Switch from "@mui/material/Switch";

const label = { inputProps: { "aria-label": "Switch demo" } };

const CompanySettings = (props) => {
  const authCtx = useContext(MyContext);
  
  const [data1, setData] = useState({multiStore: false,
                                    multiStoreCount: "",
                                    multiLocation: false,
                                    multiLocationCount: "",
                                    menuCategoriesCount: "",
                                    itemCategoriesCount: "",
                                    itemCount: "",
                                   });
  const [multistore, setIfmultistore] = useState(false);
  const [multilocation, setIfmultilocation] = useState(false);
  const [locationCount, setlocationcount] = useState("");
  const [storeCount, setStoreCount] = useState("");
  const [menuCategoriesCount, setmenuCategoriesCount] = useState("");
  const [itemCategoriesCount, setitemCategoriesCount] = useState("");
  const [itemCount, setitemCount] = useState("");
  
useEffect(() => {
    if(JSON.stringify(authCtx.allClientData) !== JSON.stringify({}) ){
      setIfmultistore(authCtx.allClientData.multiStore);
      setIfmultilocation(authCtx.allClientData.multiLocation);
      setlocationcount(authCtx.allClientData.multiLocationCount);
      setStoreCount(authCtx.allClientData.multiStoreCount);
      setmenuCategoriesCount(authCtx.allClientData.menuCategoriesCount);
      setitemCategoriesCount(authCtx.allClientData.itemCategoriesCount);
      setitemCount(authCtx.allClientData.itemCount);
    }}, [authCtx.allClientData]);

  const handlemultistore = (event) => {
    setIfmultistore(event.target.checked);
    setStoreCount("");
    setData({...data1, multiStore:event.target.checked });
    authCtx.setallClientData({...authCtx.allClientData, multiStore: event.target.checked})
  };

  const handlemultilocation = (event) => {
    setIfmultilocation(event.target.checked);
    setlocationcount("");
    authCtx.setallClientData({...authCtx.allClientData, multiLocation: event.target.checked})
    setData({...data1, multiLocation:event.target.checked });
  };

  useEffect(() => {
    props.onData(data1);
  }, [data1]);


  return (
    <div>
      <Card className={classes.card2}>
        <div className={classes.heading}>
          <h3> Company Settings </h3>
        </div>
        
          <div className={classes.inputs_container_location}>
          <div className={classes.checkbox}>
            <label className={classes.checkbox}>Multi-Store</label>
            <Switch
              {...label}
              checked={multistore}
              onChange={handlemultistore}
            />
          </div>
          {multistore ? (
            <TextField
              id="multistore-count"
              label="MultStore-Count"
              type="number"
              value={storeCount}
              variant="standard"
              className={classes.testttt} 
              InputProps={{ inputProps: { min: 0, max: 9999999 } }}
              onChange={(e)=>{setData({...data1, multiStoreCount:e.target.value });
                              setStoreCount(e.target.value)
                              authCtx.setallClientData({...authCtx.allClientData, multiStoreCount: e.target.value})}}
              required={props.isRequired}
            />
          ) : (
            <TextField
              id="multistore-count"
              label="MultStore-Count"
              value={storeCount}
              variant="standard"
              disabled
            />
          )}
          <div className={classes.checkbox}>
            <label className={classes.checkbox}>Multi-Location</label>
            <Switch
              {...label}
              checked={multilocation}
              onChange={handlemultilocation}
            />
          </div>
          {multilocation ? (
            <TextField
              id="multilocaton-count"
              label="MultiLocaton-Count"
              type="number"
              value={locationCount}
              variant="standard"
              className={classes.testttt} 
              InputProps={{ inputProps: { min: 0, max: 9999999 } }}
              onChange={(e)=>{setData({...data1, multiLocationCount:e.target.value });
                                        setlocationcount(e.target.value)
                                        authCtx.setallClientData({...authCtx.allClientData, multiLocationCount: e.target.value})}}
              required={props.isRequired}
            />
          ) : (
            <TextField
              id="multilocaton-count"
              label="MultiLocaton-Count"
              value={locationCount}
              variant="standard"
              disabled
            />
          )}
          <div>
            <TextField
              id="menu-categories-count"
              label="Menu Categories Count"
              type="number"
              variant="standard"
              className={classes.testttt} 
              InputProps={{ inputProps: { min: 0, max: 9999999 } }}
              value={menuCategoriesCount}
              onChange={(e)=>{
                authCtx.setallClientData({...authCtx.allClientData, menuCategoriesCount: e.target.value})
                setmenuCategoriesCount(e.target.value)
                setData({...data1, menuCategoriesCount:e.target.value });}}
              required={props.isRequired}
            />
          </div>
   
          <div>
            <TextField
              id="item-categories-count"
              label="Item Categories Count"
              variant="standard"
              value={itemCategoriesCount}
              type="number" 
              onChange={(e)=>{
                authCtx.setallClientData({...authCtx.allClientData, itemCategoriesCount: e.target.value})
                setitemCategoriesCount(e.target.value)
                setData({...data1, itemCategoriesCount:e.target.value });}}
              className={classes.testttt} 
              InputProps={{ inputProps: { min: 0, max: 9999999 } }}
              required={props.isRequired}
            />
          </div>
          <div>
            <TextField
              id="item-count"
              label="Item Count"
              variant="standard"
              type="number"
              className={classes.testttt} 
              InputProps={{ inputProps: { min: 0, max: 9999999 } }}
              value={itemCount}
              onChange={(e)=>{
                authCtx.setallClientData({...authCtx.allClientData, itemCount: e.target.value})
                setitemCount(e.target.value)
                setData({...data1, itemCount:e.target.value });}}
              required={props.isRequired} 
            />
          </div>
        </div>
      </Card>
    </div>
  );
};

export default CompanySettings;
