import React, { useContext, useState } from "react";
import classes from "./Header.module.css";
import Logo from '../../../../assets/Logo.png'
import home from '../../../../assets/home.png'
import leftgrp from '../../../../assets/leftgrp.png'
import { Link } from "react-router-dom";
import Sideicon from '../../../../assets/sidebar.png'
import AuthContext from "../../../../Common/auth-context";

const Header = ({toggle}) => {
  const authCtx = useContext(AuthContext);

  return (
    <div className={classes.heading}>
        <div className={classes.image}><img src={home} alt=""/> </div>
        <div className={classes.leftgrp}><img src={leftgrp} alt=""/> </div>
      <div className={classes.navbar}>
        <div className={classes.logo}>
          <img src={Logo} alt="" />
        </div>
        <div className={classes.navbarbtns}>
        <Link to='/contactus'><button className={classes.contactbtn}>CONTACT US</button></Link>
        <Link to='/login'> <button className={classes.logbtn}>LOGIN</button></Link>
        </div>
        <div className={classes.mobileview}>
      <div onClick={()=> authCtx.setopenside(true)}>
          <img src={Sideicon} alt='' style={{position:'absolute', right: '50px', top:'50px'}}/>
        </div>
      </div>
      </div>
      <div className={classes.head}>
        <div className={classes.description}>
          <h1>You can almost <span style={{fontWeight: 700}}>taste</span> it</h1>
          <p>Create your digital menus quickly and easily with QR code Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
          <Link to='/menu'><button>Get in Touch!</button></Link>
        </div>
        <div>
        </div>
      </div>
    </div>
  );
};

export default Header;
