import { useContext, useState } from 'react';
import axios from 'axios';
import Card from '../../../Common/UI/Card/Card';
import classes from "./Profile.module.css";
import ClientAccount from "./ClientAccount";
import ClientInformation from "./ClientInformation";
import CompanySettings from "./CompanySettings";
import Button from '@mui/material/Button';
import AuthContext from "../../../Common/auth-context";
import ChangePassModal from './Change Pass Modal/ChangePassModal';

const Profile = (props) => {
  const rows = props;

  const authCtx = useContext(AuthContext);

  const handleClose = () => {
    authCtx.setOpenLocationModal(false);
  };
  const handle = () => {
    console.log("companyData");
    console.log(authCtx.companyData);
    console.log("clientyData");
    console.log(authCtx.clientyData);
    console.log("accountData");
    console.log(authCtx.accountData);
  };

  const [data, setData] = useState([]);
  const [data1, setData1] = useState({
    companyName: "test",
    clientName: "test",
    clientPhone: "",
    clientAddress: "111",  
    menuCategoriesCount: "10",
    itemCategoriesCount: "250",
    itemCount: "500",
    IsActive: true,
    SubscriptionDate: "2023/03/29",
    expiryDate: "2024/03/29",
    multiStore: true,
    multiStoreCount: "2",
    multiLocation: true,
    multiLocationCount: "6",
    clientPassword: "swed",
    clientUserName: "test",
});

  const fetchUserData = () => {
    axios.get("//localhost:3001/augmenu/clients/all")
    .then(response => {
      setData(response.data);
    })
    .catch(error => {
      console.log(error);
    });
      console.log(data)
  }
  

  const axiosInstance = axios.create({
    baseURL: '//localhost:3001/augmenu/clients',
  });

  const sendData = () => {
    axiosInstance.post('/add', data1)
        .then(response => {
          console.log(response);
        })
        .catch(error => {
          console.log(error);
        });
        console.log(data1);
      }
  
  
 
  
       return (
        <div className={classes.profileContainer}>
         
        <div className={classes.profileheader}>
          <p className={classes.profilehead}>Client's Name </p>
         
          <Button variant="contained" className={classes.Passwordchange} 
          type="submit" onClick={() => {authCtx.setOpenLocationModal(true);}}>Change Password</Button>
      </div>
            <ClientAccount rows={rows} />
            <ChangePassModal handleClose={handleClose}/>
         
      </div>
       );
    };
    
     export default Profile;