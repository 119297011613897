import React, { useContext, useState } from 'react'
import TextField from "@mui/material/TextField";
import Card from '../../../../Common/UI/Card/Card'

import classes from './Modal.module.css'
import Subscription from './Subcription';
import AuthContext from "../../../../Common/auth-context";

const ClientAccount = (props) => {
  const authCtx = useContext(AuthContext);

  const [clientUserName, setclientUserName] = useState(authCtx.accountData.clientUserName);
  const [clientPassword, setclientPassword] = useState(authCtx.accountData.clientPassword);
  const passData = (p) => {
    props.onData(p);
  };
  return (
    <div>
      <Card className={classes.card1}>
              <div className={classes.heading}>
                <h3> Client Account & Subscription</h3>
              </div>
              <div className={classes.inputs_container1}>
                <TextField
                  id="client-username"
                  label="Client UserName"
                  type="search"
                  variant="standard"
                  value={clientUserName}
                  onChange={(e)=>{setclientUserName(e.target.value );}}
                  required={props.isRequired}
                />
                <TextField
                  id="client-password"
                  label="Client Password"
                  type="password"
                  variant="standard"
                  value={clientPassword}
                  onChange={(e)=>{setclientPassword(e.target.value);}}
                  required={props.isRequired}
                />
                
              </div>
              <Subscription passData={passData} clientUserName={clientUserName} clientPassword={clientPassword}/>
            </Card>
    </div>
  )
}

export default ClientAccount