import React, { useContext } from 'react'
import classes from "./navbar.module.css";
import Logo from '../../../../assets/Logo.png'
import { Link } from 'react-router-dom';
import Sideicon from '../../../../assets/sidebar.png'
import AuthContext from '../../../../Common/auth-context';

const Navbar = () => {
  const authCtx = useContext(AuthContext)

  return (
    <div className={classes.navbar}>
      <div className={classes.logo}><Link to='/'>
        <img src={Logo} alt="" /></Link>
      </div>
      <div className={classes.navbarbtns}>
      <Link to='/contactus'><button className={classes.contactbtn}>CONTACT US</button></Link>
      <Link to='/login'> <button className={classes.logbtn}>LOGIN</button></Link>
      </div>
      <div className={classes.mobileview}>
      <div onClick={()=>authCtx.setopenside(false)}>
          <img src={Sideicon} alt='' style={{position:'absolute', right: '50px', top:'50px'}}/>
        </div>
      </div>
    </div>
  )
}

export default Navbar