import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import axios from 'axios';

import classes from "./Clients.module.css";
import AuthContext from "../../../../Common/auth-context";
import CreateIcon from "@mui/icons-material/Create";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { EnhancedTableToolbar } from "./EnhancedTableToolbar";
import EnhancedTableHead from "./EnhancedTableHead";
import AddModal from "../Add Edit Clients/Modal";
import { TablePagination } from "@mui/material";

function createData(
  store_name,
  client_name,
  location,
  phonenumber,
  subs_date,
  expiry_date,
  edit
) {
  return {
    store_name,
    client_name,
    location,
    phonenumber,
    subs_date,
    expiry_date,
    edit,
  };
}

const headCells = [
  {
    id: "store_name",
    label: "Store Name",
  },
  {
    id: "client_name",
    label: "Client Name",
  },
  {
    id: "location",
    label: "Location",
  },
  {
    id: "phonenumber",
    label: "PhoneNumber",
  },
  {
    id: "subs_date",
    label: "Subscription",
  },
  {
    id: "expiry_date",
    label: "Expiry",
  },
  {
    id: "edit",
    label: "Edit",
  },
];


export const rows =[
  {
    id: 175,
    store_name: "Dr Food",
    client_name: "Dr. Food",
    location: "Jounieh",
    phonenumber: "67154865",
    subs_date: '12/10/2022',
    expiry_date: '12/10/2023',
  },
  {
    id: 2200,
    store_name: "Milka",
    client_name: "Hasan",
    location: "Beirut",
    phonenumber: "67154865",
    subs_date: '15/10/2022',
    expiry_date: '15/10/2023',
  },
  {
    id: 11,
    store_name: "CupcakeShop",
    client_name: "Ahmad",
    location: "Saida",
    phonenumber: "67154865",
    subs_date:'1/8/2022',
    expiry_date: '1/2/2023',
  },
  {
    id: 1,
    store_name: "Snippes",
    client_name: "Jaafar",
    location: "Beirut",
    phonenumber: "67154865",
    subs_date: '20/10/2022',
    expiry_date: '20/10/2023',
  },
  {
    id: 13,
    store_name: "CupcakeShop",
    client_name: "hsen",
    location: "Saida",
    phonenumber: "67154865",
    subs_date:'25/2/2023',
    expiry_date: '25/2/2024',
  },
  {
    id: 10,
    store_name: "Galaxy",
    client_name: "Ahmad",
    location: "Nabatieh",
    phonenumber: "67154865",
    subs_date: '12/10/2022',
    expiry_date: '12/10/2023',
  },
  {
    id: 3,
    store_name: "Mentos",
    client_name: "ali",
    location: "Tyre",
    phonenumber: "67154865",
    subs_date: '22/9/2022',
    expiry_date: '22/9/2023',
  },
  {
    id: 2,
    store_name: "Kitkat",
    client_name: "moussa",
    location: "Saida",
    phonenumber: "67154865",
    subs_date: '20/11/2022',
    expiry_date: '20/11/2023',
  },
];

const Clients = () => {
  const [data1, setData1] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [httpError, setHttpError] = React.useState();
  const [selected, setSelected] = React.useState([]);
  const [editpoints, setEditpoints] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [clientname, setClientname] = React.useState("");
  const [storename, setStorename] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [address, setAddress] = React.useState("");
  const authCtx = React.useContext(AuthContext);
  const [dataa, setdataa] = React.useState(data1);
let aaa =  { id: 2, store_name: "test", client_name: "test", location: "test", phonenumber: "67154865", subs_date: '20/11/2022', expiry_date: '20/11/2023',}

React.useEffect(() =>  {
  const fetchData = async () => {
    const response = await fetch("//localhost:3001/augmenu/clients/all")
    if (!response.ok) {
      throw new Error('Something went wrong!');
    }
    const responseData = await response.json();
  
    const loadedData = [];

    for (const key in responseData) {
      loadedData.push({
        id: responseData[key].ID,
        store_name: responseData[key].CompanyName,
        client_name: responseData[key].ClientName,
        location: responseData[key].Location,  
        phonenumber: responseData[key].PhoneNumber,
        subs_date: responseData[key].SubDate.slice(0,10),
        expiry_date: responseData[key].ExpDate.slice(0,10),
        menuCategoriesCount: responseData[key].MenuCatCount,
        itemCategoriesCount: responseData[key].ItemCatCount,
        itemCount: responseData[key].ItemCount,
        IsActive: responseData[key].IsActive,
        multiStore: responseData[key].IsMultiStore,
        multiStoreCount: responseData[key].StoreCount,
        multiLocation: responseData[key].IsMultiLocation,
        multiLocationCount: responseData[key].LocationCount,
        clientPassword: responseData[key].clientPassword,
        clientUserName: responseData[key].clientUserName,
      });
    }
    setData1(loadedData);
    setIsLoading(false);
   
  }
  fetchData().catch((error) => {
    setIsLoading(false);
    setHttpError(error.message);
  });
  console.log(data1)
  authCtx.setrefreshdata(false);
}, [authCtx.refreshdata]);





  const handleClose = () => {
    authCtx.setopenmodal(false);
    setStorename("");
    setClientname("");
    setPhone("");
    setAddress("");
  };

  

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const toggle = () => {
    setEditpoints(!editpoints);
    console.log("fuck you");
  };

  React.useEffect(() => {
    if(JSON.stringify(authCtx.clientyData) !== JSON.stringify({}) ){
    setdataa([{ id: 9,
                         store_name:authCtx.clientyData.companyName , 
                         client_name:authCtx.clientyData.clientName , 
                         location: authCtx.clientyData.clientAddress , 
                         phonenumber:authCtx.clientyData.clientPhone , 
                         subs_date:authCtx.accountData.SubscriptionDate , 
                         expiry_date:authCtx.accountData.expiryDate ,} , ...dataa])
  }
  }, [authCtx.clientyData]);
  const handleSubmit = (event) => {
    event.preventDefault();
console.log("doneee")  };


  // const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    // if (isLoading) {
    //   return (
    //     <section className={classes.MealsLoading}>
    //       <p>Loading...</p>
    //     </section>
    //   );
    // }
    
    // if (httpError) {
    //   return (
    //     <section className={classes.MealsError}>
    //       <p>{httpError}</p>
    //     </section>
    //   );
    // }
  


  return (
    <div className={classes.clientsContainer}>
      <div className={classes.clientsHeading}>
        <p className={classes.clientsHead}>Clients</p>
        <p>Clients Information</p>
      </div>
      <div className={classes.clientsTableContainer}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <EnhancedTableToolbar
            array={data1}
            // numSelected={selected.length}
            // selected={selected}
            handleClose={handleClose}
            store_name={storename}
            client_name={clientname}
            address={address}
            phone={phone}
          ></EnhancedTableToolbar>
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"} 
            >
              <EnhancedTableHead
                // numSelected={selected.length}
                // onSelectAllClick={handleSelectAllClick}
                rowCount={data1.length}
                headCells={headCells}
              /> 
              <TableBody>
                {authCtx.clientfilter
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        onDoubleClick={() => {
                          authCtx.setopenmodal(true);
                          authCtx.setclienttitle('Edit Client Information');
                          for (let i = 0; i < data1.length; i++) {
                            if (data1[i].id === row.id) {
                              console.log(data1[i]);
                              authCtx.setallClientData(data1[i]);
                              authCtx.seteditdata(true);
                              
                              break;

                            }
                          }
                        }}
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                      >
                        <TableCell>
                          
                        </TableCell>
                        <TableCell>{row.store_name}</TableCell>
                        <TableCell align="left">{row.client_name}</TableCell>
                        <TableCell align="left">{row.location}</TableCell>
                        <TableCell align="left">{row.phonenumber}</TableCell>
                        <TableCell align="left">{row.subs_date}</TableCell>
                        <TableCell align="left">{row.expiry_date}</TableCell>
                        <TableCell align="left">
                          <CreateIcon
                            onClick={() => {
                              authCtx.setopenmodal(true);
                              authCtx.setclienttitle('Edit Client Information');
                              setStorename(row.store_name);
                              setClientname(row.client_name);
                              setAddress(row.location);
                              setPhone(row.phonenumber);
                            }}
                            class={classes.edit}
                          />{" "}
                          <DeleteForeverIcon 
                            class={classes.freeze}
                          />
                        </TableCell>
                      </TableRow>
                    );

                    <AddModal
                      store_name={row.store_name}
                      client_name={row.client_name}
                      address={row.location}
                      phone={row.phonenumber}
                    />;
                  })}

                <div className={classes.dropdown}>
                  {editpoints && (
                    <div id="myDropdown" class={classes.dropdowncontent}>
                      <p onClick={toggle}>Edit</p>
                      <p>Freeze</p>
                    </div>
                  )}
                </div>
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={data1.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    </div>
  );
};
export default Clients

