import React, { useContext, useEffect, useState } from 'react'
import classes from './CategoryModal.module.css';

import MenuItem from '@mui/material/MenuItem';
import Card from '../../../../Common/UI/Card/Card';
import TextField from '@mui/material/TextField';
import AuthContext from "../../../../Common/auth-context";
const types = [
   {value: 'menu', label: 'Menu',},  {value: 'item', label: 'Item', },
  ];
  
  const menu = [
    { id: 1, type: "Menu", name: "None"},
    { id: 2, type: "Menu", name: "Main menu"},
    { id: 3, type: "Menu", name: "Combo"},
    { id: 4, type: "Menu", name: "Delivery"},
    { id: 5, type: "Menu", name: "Take Away"},
    
  ];
const AddCategory = (props) => {
  const authCtx = useContext(AuthContext);

  const [data, setData] = useState({Type: "",
                                    Name: "",
                                    Link: "",
                                    Visible: true,
                                   });
  const [categotyType, setcategotyType] = useState(authCtx.categoryData.Type)
  const [name, setname] = useState(authCtx.categoryData.Name)
  const [link, setlink] = useState(authCtx.categoryData.Link)
  const [visible, setvisible] = useState(authCtx.categoryData.Visible)
  
  useEffect(() => {
    props.onData(data);
  }, [data]);
  return (
      <Card className={classes.card1}>
          <div className={classes.heading}><h3> Category Information </h3></div>
          <div className={classes.inputs_location_container}>    
         <TextField
          id="categotyType"
          select
          label="Categoty Type *"
          value={categotyType}
          variant="standard"
          onChange={(e)=>{
            if (e.target.value === "menu") {
              console.log("test")
              setData({...data,  Link: 4 })
            }
            setcategotyType(e.target.value);
            setData({...data, Type:e.target.value });}}
            required={props.isRequired}
        >
          {types.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
         <TextField
          id="name"
          label="Category Name"
          type="search"
          variant="standard"
          value={name}
          onChange={(e)=>{
            setname(e.target.value);
            setData({...data, Name:e.target.value });}}
            required={props.isRequired}
        />
         <TextField
          id="link"
          select
          label="Link to "
          variant="standard"
          value={link}
          onChange={(e)=>{
            setlink(e.target.value);
            setData({...data, Link:e.target.value });}}
        >
          {props.menuData.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.MenuName}
            </MenuItem>
          ))}
          </TextField>
          <TextField
          id="visible"
          select
          label="Visible *"
          variant="standard"
          value={visible}
          onChange={(e)=>{
            setvisible(e.target.value);
            setData({...data, Visible:e.target.value });}}
        >
          
            <MenuItem value={1}>
              True
            </MenuItem>
            <MenuItem value={0}>
              False
            </MenuItem>
       
          </TextField>
            
          </div> 
        </Card>
  
  )
}

export default AddCategory

