import * as React from 'react';
import PropTypes from 'prop-types';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import Checkbox from '@mui/material/Checkbox';

import classes from './Clients.module.css';

function EnhancedTableHead(props) {
    const { onSelectAllClick,numSelected, rowCount, headCells , rows } = props;
    return (
      <TableHead 
      className={classes.headingRow} >
        <TableRow>
          <TableCell padding="checkbox">
            {/* <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all desserts',
              }}
            /> */}
          </TableCell>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              // className={classes.headingRow} 
            >
                {headCell.label}
            </TableCell>
            
          ))} 
        </TableRow>

        
        <TableRow>
          <TableCell>sdfgh</TableCell>
        </TableRow>  
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  export default EnhancedTableHead;