import React from 'react'
import classes from './About.module.css'
import phone from '../../../../assets/aboutphone.png'
import appstore from '../../../../assets/appstore.png'
import googleplay from '../../../../assets/googleplay.png'

const About = () => {
  return (
    <div className={classes.about_con}>
        <div className={classes.about}>
            <h1>How it <span style={{fontWeight:700}}>Works</span>?</h1>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
        <div className={classes.VR}>
            <div className={classes.description}>
                <h1>Digitalizing your <span style={{fontWeight:700}}>VR Menu</span></h1>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            </div>
            <div className={classes.image}>
                <img src={phone} alt='' width='100%'/>
            </div>
            <div className={classes.download}>
                <img src={appstore} alt='' width='100%'/>
                <img src={googleplay} alt=' ' width='100%'/>
            </div>
        </div>
            <div className={classes.descriptionmobile}>
                <h1>Digitalizing your <span style={{fontWeight:700}}>VR Menu</span></h1>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            </div>
    </div>
  )
}

export default About