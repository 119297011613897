import classes from './Sidebar.module.css'
import { Link } from "react-scroll";
import Logo from '../../../../../assets/Logo.png'
import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import sidebarabs1 from '../../../../../assets/leftgrp.png'
import AuthContext from '../../../../../Common/auth-context';


export default function Sidebar({toggle, isOpen}) {
  
  const authCtx = React.useContext(AuthContext);
  const [state, setState] = React.useState({
    right: false,
  });

  const list = (anchor) => (
    <>
    <img src={sidebarabs1} alt='' style={{
      position:'absolute',
      top: '250px',
      width:'15%',
      left: 0,
    }} />
    <img src={sidebarabs1} alt='' style={{
      position:'absolute',
      bottom: '730px',
      width:'15%',
      right: 0,
      rotate:'180deg',
    }} />
    <Box
      sx={{ width:  '55vw' }}
      role="presentation"
      onClick={()=>authCtx.setopenside(false)}
      onKeyDown={()=>authCtx.setopenside(false)}
      className={classes.container}
    >
      <List>
          <ListItem  disablePadding>
            <ListItemButton>
              <ListItemText style={{
                textAlign: 'center'
              }}>
              <Link  to='home' smooth={true}  duration={500} onClick={toggle}>
                { <img src={Logo} alt='Logo' className={classes.logo}/> }
                     </Link>
              </ListItemText>
            </ListItemButton>
          </ListItem>
          </List>
          <List>
          <ListItem  disablePadding>
            <ListItemButton>
              <ListItemText className={classes.text}>
              <Link  to='login' smooth={true}  duration={500} onClick={toggle}>
                        Login
                     </Link>
              </ListItemText>
            </ListItemButton>
          </ListItem>
          {/* <Divider color='white' width='70%' style={{justifySelf: 'center', alignSelf:'center', marginLeft:'15%'}}/> */}
          {/* <Divider color='white' width='70%' style={{justifySelf: 'center', alignSelf:'center', marginLeft:'15%'}}/> */}
            <ListItem  disablePadding>
            <ListItemButton>
              <ListItemText className={classes.text}>
              <Link  to='teammob' smooth={true}  duration={500} onClick={toggle}>
                        Pricing
                     </Link>
              </ListItemText>
            </ListItemButton>
          </ListItem>
          {/* <Divider color='white' width='70%' style={{justifySelf: 'center', alignSelf:'center', marginLeft:'15%'}}/> */}
          <ListItem  disablePadding>
            <ListItemButton>
              <ListItemText className={classes.text}>
              <Link  to='contactus' smooth={true}  duration={500} onClick={toggle}>
                        Contact Us
                     </Link>
              </ListItemText>
            </ListItemButton>
          </ListItem>
      </List>
    </Box></>
  );

  return (
    <div>
      {['right'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Drawer
            anchor={anchor}
            open={authCtx.openside}
            onClose={()=>authCtx.setopenside(false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}