import React, { useContext, useState } from "react";
import classes from "./Items.module.css";
import AuthContext from "../../../Common/auth-context";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import CheckIcon from "@mui/icons-material/Check";
import TextField from "@mui/material/TextField";
import ItemModal from "./Item Modal/ItemModal";
import ItemSearchBox from "./Items Search/ItemsSearch";
import { colors } from "@mui/material";
import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import checks from '../../../assets/checks.png'
import Xchecks from '../../../assets/Xchecks.png'
 

const menu = [
{ id: 1, menuid: 1, name: "Burger"},
{ id: 2, menuid: 1, name: "Pizza"},
{ id: 3, menuid: 1, name: "Plates"},
{ id: 4, menuid: 1, name: "dessert"},
];

export const items = [
{id: 1, itemCategory: 1 , name: "Beef Burger",price: "20$", oldPrice: "21$", hasTag: "Standard", allergies:"Wheat" ,available: true , },
{id: 2, itemCategory: 1 , name: "Cheese Burger",price:"25$", oldPrice: "26$", hasTag: "Combo", allergies:"Wheat" ,available: true ,},
{id: 3, itemCategory: 1 , name: "Checken Burger",price: "18$", oldPrice: "", hasTag: "Standard", allergies:"Wheat" ,available: true , },
{id: 4, itemCategory: 1 , name: "American Burger",price: "24$", oldPrice: "25$", hasTag: "Combo", allergies:"Wheat" ,available: true , },
{id: 5, itemCategory: 2 , name: "Vegrtarian Pizza",price: "13$", oldPrice: "18$", hasTag: "Special Offer", allergies:"Wheat" ,available: true , },
{id: 6, itemCategory: 2 , name: "Margherita Pizza",price: "13$", oldPrice: "17$", hasTag: "Special Offer", allergies:"Wheat" ,available: true , },
{id: 7, itemCategory: 2 , name: "Pepperoni Pizza",price:"15$", oldPrice: "20$", hasTag: "Special Offer", allergies:"Wheat" ,available: true , },
{id: 8, itemCategory: 3 , name: "Greek Salad",price: "20$", oldPrice: "", hasTag: "New", allergies:"Crustacean shellfish" ,available: true , },
{id: 9, itemCategory: 3 , name: "Beef Steak",price: "25$", oldPrice: "27$", hasTag: "Standard", allergies:"" ,available: true , },
{id: 10, itemCategory: 4 , name: "Cupcake",price:"10$", oldPrice: "", hasTag: "New", allergies:"Milk" ,available: true ,visible: true, },
{id: 11, itemCategory: 4 , name: "Ice cream sandwich", price: "13$",oldPrice: "", hasTag: "New", allergies:"Milk, Peanuts, Tree nuts" ,available: true ,},
{id: 12, itemCategory: 4 , name: "Crepe",price: "14$", oldPrice: "19$", hasTag: "Special Offer", allergies:"Milk, Eggs" ,available: true , },
{id: 13, itemCategory: 4 , name: "Waffle",price: "17$", oldPrice: "21$", hasTag: "Special Offer", allergies:"Milk, Eggs" ,available: true , },
];


const headCells = [
  {
    id: "name",
    label: "Name",
  },
  {
    id: "itemCategory",
    label: "Item Category",
  },
  {
    id: "description",
    label: "Description",
  },
  {
    id: "price",
    label: "Price",
  },
  {
    id: "oldPrice",
    label: "Old Price",
  },
  {
    id: "hasTag",
    label: "Has Tag",
  },
  {
    id: "allergies",
    label: "Allergies",
  },
  {
    id: "available",
    label: "Available",
  },
];

 

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 55,
  height: 35,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(22px)',
      '& .MuiSwitch-thumb:before': {
        backgroundImage:`url(${checks})`,},
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor:  '#DB6A1B',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: '#FFFFFF',
    width: 28,
    height: 28,
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage:  `url(${Xchecks})`   ,
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: '#DB6A1B',
    borderRadius: 20 / 2,
  },
}));

function EnhancedTableHead(props) {

  return (
    <TableHead  style={{background:'transparent'}} >
      <TableRow  >
        {headCells.map((headCell) => (
          <TableCell  key={headCell.id}>
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;
  const authCtx = useContext(AuthContext);

 
    // <Toolbar
    //   sx={{
    //     pl: { sm: 2 },
    //     pr: { xs: 1, sm: 1 },
    //     ...(numSelected > 0 && {
    //       bgcolor: (theme) =>
    //         alpha(
    //           theme.palette.primary.main,
    //           theme.palette.action.activatedOpacity
    //         ),
    //     }),
    //   }}        
    //   className={classes.tableHeading}
    // >
    //   {numSelected > 0 ? (
    //     <Typography
    //       sx={{ flex: "1 1 100%" }}
    //       color="inherit"
    //       variant="subtitle1"
    //       component="div"
    //     >
    //       {numSelected} selected
    //     </Typography>
    //   ) : (
    //     <Typography
    //       sx={{ flex: "1 1 100%" }}
    //       variant="h6"
    //       id="tableTitle"
    //       component="div"
    //     >
    //       Items
    //     </Typography>
    //   )}

    //   <ItemSearchBox data={items} cat={menu} />

    //   {numSelected > 0 ? (
    //     <Tooltip title="Delete">
    //       <IconButton>
    //         <DeleteIcon />
    //       </IconButton>
    //     </Tooltip>
    //   ) : (
    //     <Tooltip title="Filter list">
    //       <IconButton>
    //         <FilterListIcon />
    //       </IconButton>
    //     </Tooltip>
    //   )}
      
    // </Toolbar>
    // AddCircleIcon
    // < button onClick={() => {authCtx.setOpenItemModal(true);}}  className={classes.addbtn}>ADD ITEM</button>
  
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const Items = () => {
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const authCtx = useContext(AuthContext);
  const [openModule, setopenModule] = useState("Add Item");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - menu.length) : 0;

    const handleClose = () => {
      authCtx.setOpenItemModal(false);
    };

  return (
    <div className={classes.itemsContainer}>
        <div className={classes.itemsHeading}>
            <p className={classes.itemHead}>Items</p>
            < button onClick={() => {authCtx.setOpenItemModal(true);}}  className={classes.addbtn}>ADD ITEM</button>
            {/* <p>Manage your Items</p> */}
        </div>
    <div className={classes.itemsTableContainer}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer
            className={classes.headingRow}>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead 
              numSelected={selected.length}
              rowCount={menu.length}
            />
            <TableBody>
              {menu.map((menu, index) => {
                return (
                  <> 
                    {authCtx.itemsfilter
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((items, index1) => {
                        if (items.itemCategory === menu.id) {
                          return (
                            <TableRow
                              className={classes.itemRow}
                              onDoubleClick={()=>{authCtx.setOpenItemModal(false);
                                setopenModule("Edit Your Item");
                              }}
                            >
                              <TableCell>{items.name}</TableCell>
                              <TableCell>{menu.name}</TableCell>
                              <TableCell>{items.description}</TableCell>
                              <TableCell>{items.price}</TableCell>
                              <TableCell>{items.oldPrice}</TableCell>
                              <TableCell>{items.hasTag}</TableCell>
                              <TableCell>{items.allergies}</TableCell>
                              <TableCell>
                                {items.available &&   <FormGroup>
      <FormControlLabel
        control={ <FormControlLabel
          control={<MaterialUISwitch sx={{ m: 1 }} defaultChecked />}
           
        />}
        
      /> </FormGroup>}
                              </TableCell>
                            </TableRow>
                          );
                        } else {
                          return <></>;
                        }
                      })}
                  </>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={menu.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
     <ItemModal handleClose={handleClose}
     lable={openModule}/>
      <div className={classes.btns}>
                                       
                                       <button className={classes.btnsave} >
                                       Save
                                      </button>
                                       <button className={classes.btncancel}  >
                                       Discard
                                       </button>  
                                    </div>
    </div>
   
    </div>
  );
};
export default Items

