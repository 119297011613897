import React from "react";
import classes from "./Clients.module.css";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Link, useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from "swiper/react";
import img1 from '../../../../assets/client3.png'
import arrow from '../../../../assets/arrow.png'
import leftgrp from '../../../../assets/leftgrp.png'

import "swiper/css";
import "swiper/css/navigation";

import ClientCard from "./Client Card/ClientCard";
import { Navigation } from "swiper/modules";

const array = [
  {
    image: img1,
  },
  {
    image: img1,
  },
  {
    image: img1,
  },
  {
    image: img1,
  },
  {
    image: img1,
  },
  {
    image: img1,
  },
  {
    image: img1,
  },
];

const Clients = () => {
  const navigate = useNavigate();

  const styles = {
    style1: {
      position: "relative",
      right: "5%",
      width: "fit-content",
      color: "black",
      zIndex: 11,
    },
    style2: {
      position: "relative",
      right: "7%",
      width: "fit-content",
      color: "black",
      zIndex: 11,
    },
  };
  return (
    <div className={classes.trends_container}>
      <div className={classes.trends_content}>
              <img src={leftgrp} alt=""  style={{position: 'absolute', left: 0, marginTop: '350px' }}/>
        <div className={classes.title_container}>
          <h1>A few of the <span style={{fontWeight: 700}}>Restaurants</span>  we work with</h1>
          <div className={classes.arrowDesktop}>
            <div className="button-prev-slide" style={styles.style2}>
              <img src={arrow} alt="" width='70%' style={{rotate: '180deg'}}/>
            </div>
            <div className="button-next-slide" style={styles.style1}>
              <img src={arrow} alt="" width='70%' />
            </div>
          </div>
        </div>
        <div className={classes.swiper}>
          <Swiper
            // slidesPerView={5}
            spaceBetween={0}
            effect={"fade"}
            navigation={{
              nextEl: ".button-next-slide",
              prevEl: ".button-prev-slide",
            }}
            modules={[Navigation]}
            style={{
              // margin: '5% 0 5% 0',
              zIndex: '1'
            }}
          breakpoints={{
            0: {
              slidesPerView: 3,
              },
            651: {
              slidesPerView: 3,
              },
            951: {
              slidesPerView: 4,
            },
          1300: {
            slidesPerView: 6,
          },
        }}
          >
            {array.map((props) => {
              return (
                <SwiperSlide
                  style={{
                    width: 'fit-content  !important',
                    borderRadius: '30px',
                    // backgroundColor: 'blue',
                    // paddingLeft: '0.2%',
                  }}
                >
                  {/* <ClientCard
                    image={props.image}
                    title={props.title}
                    description={props.description}
                  /> */}
                  <img src={props.image} alt="" style={{width: '70%', borderRadius: '30px', background:'#32a'}}  />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Clients;
