import React from 'react'
import classes from './Footer.module.css'
import footerimg from '../../../../assets/footerimg.png'
import footerabs from '../../../../assets/footerabs.png'
import facebooklogo from '../../../../assets/facebooklogo.png'
import instalogo from '../../../../assets/instalogo.png'

const Footer = () => {
  return (
    <div className={classes.footer_con}>
        <div className={classes.footer_content}>
            <div className={classes.description}>
                <p>Building your virtual <span style={{fontWeight: 700}}>Dishes</span> and creating a real <span style={{fontWeight: 700}}>experience</span> !</p>
                <div className={classes.rotate}><img src={footerimg} alt='' style={{transformOrigin: 'center center', width: '100%'}}/></div>
            </div>
            <div>
            </div>
        </div>
        <div className={classes.companyinfo}>
            <div className={classes.copyright}> Copyright &copy; @metariba</div>
            <div className={classes.socials}>
              <div><p>Privacy Policy</p></div>
              <div><p>Terms & Conditions</p></div>
              <div><img src={facebooklogo} alt='' width='70%'/></div>
              <div><img src={instalogo} alt='' width='70%'/></div>
            </div>
        {/* <img src={footerabs} alt='' style={{position:'absolute', left: 0, marginBottom: '200px', zIndex: 0, width: 'calc(15rem + 8vw)', overflow: 'hidden'}}/> */}
        </div>
    </div>
  )
}

export default Footer