import QRCodee from "qrcode";
import { useEffect, useRef, useState } from "react";
import classes from './QRCode.module.css';
import WifiQRCode from "./WifiQRCode";

const QRCode = () => {  
    const [text, setText] = useState("https://digitalmenu.pages.dev/menu");
  const canvasRef = useRef();

  useEffect(() => {
    QRCodee.toCanvas(
      canvasRef.current,
      text || " ",
      (error) => error && console.error(error)
    );
  }, [text]);
  return (
    <div className={classes.qrContainer}>
      <div className={classes.qrMenuContainer}> 
      <h1>Menu QR Code</h1> 
      <canvas ref={canvasRef} className={classes.qrMenu}/>
      </div>
      <WifiQRCode/> 
    </div>
  )
}

export default QRCode
