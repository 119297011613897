import React, { useContext, useState } from "react";
import AuthContext from "../../Common/auth-context";
import classes from "./menu.module.css";
import burger from "../../assets/burger.jpeg";
import pizza from "../../assets/pizza.jfif";
import meat from "../../assets/meat.jfif";
import PlaceIcon from "@mui/icons-material/Place";
import WifiIcon from "@mui/icons-material/Wifi";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SearchBox from "./Searchbox/Search";
import Footer from "./FooterSection";
import checks from "../../assets/checks.png";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import StarIcon from "@mui/icons-material/Star";
import storeimage from '../../assets/storeimage.png'
import { Pagination } from "swiper/modules";

const labels = {
  0.5: "Useless",
  1: "Useless+",
  1.5: "Poor",
  2: "Poor+",
  2.5: "Ok",
  3: "Ok+",
  3.5: "Good",
  4: "Good+",
  4.5: "Excellent",
  5: "Excellent+",
};

function getLabelText(value) {
  return `${value} Star${value !== 1 ? "s" : ""}, ${labels[value]}`;
}

export const Burgers = [
  {
    key: 1,
    name: "Lebanese Burgers",
    image: burger,
    description: "Burger",
    price: " 8",
    about:
      "Your choice of freshly grilled meat or sofritas wrapped in a flour tortilla with rice, beans, or fajita veggies, and topped with guac, salsa, queso blanco, sour cream or cheese. kebaniiio",
  },
  {
    key: 2,
    name: "American Burgers",
    image: burger,
    price: "  15",
    about:
      "Your choice of freshly grilled meat or sofritas wrapped in a flour tortilla with rice, beans, or fajita veggies, and topped with guac, salsa, queso blanco, sour cream or cheese. kebaniiio",
  },
  {
    key: 3,
    name: "Lebanese Burgers",
    image: burger,
    description: "Burger",
    price: "  12",
    about:
      "Your choice of freshly grilled meat or sofritas wrapped in a flour tortilla with rice, beans, or fajita veggies, and topped with guac, salsa, queso blanco, sour cream or cheese. kebaniiio",
  },
  {
    key: 4,
    name: "American Burgers",
    image: burger,
    price: "  13",
    about:
      "Your choice of freshly grilled meat or sofritas wrapped in a flour tortilla with rice, beans, or fajita veggies, and topped with guac, salsa, queso blanco, sour cream or cheese. kebaniiio",
  },
  {
    key: 5,
    name: "Lebanese Burgers",
    image: burger,
    description: "Burger",
    price: "  10",
    about:
      "Your choice of freshly grilled meat or sofritas wrapped in a flour tortilla with rice, beans, or fajita veggies, and topped with guac, salsa, queso blanco, sour cream or cheese. kebaniiio",
  },
  {
    key: 6,
    name: "American Burgers",
    image: burger,
    price: "  15",
    about:
      "Your choice of freshly grilled meat or sofritas wrapped in a flour tortilla with rice, beans, or fajita veggies, and topped with guac, salsa, queso blanco, sour cream or cheese. kebaniiio",
  },
];

export const Pizza = [
  {
    key: 1,
    name: "Lebanese Pizza",
    image: pizza,
    description: "Pizza",
    price: "  17",
    about:
      "Your choice of freshly grilled meat or sofritas wrapped in a flour tortilla with rice, beans, or fajita veggies, and topped with guac, salsa, queso blanco, sour cream or cheese. kebaniiio",
  },
  {
    key: 2,
    name: "Italian Pizza",
    image: pizza,
    price: "  18",
    about:
      "Your choice of freshly grilled meat or sofritas wrapped in a flour tortilla with rice, beans, or fajita veggies, and topped with guac, salsa, queso blanco, sour cream or cheese. kebaniiio",
  },
  {
    key: 3,
    name: "Lebanese Pizza",
    image: pizza,
    description: "Pizza",
    price: "  17",
    about:
      "Your choice of freshly grilled meat or sofritas wrapped in a flour tortilla with rice, beans, or fajita veggies, and topped with guac, salsa, queso blanco, sour cream or cheese. kebaniiio",
  },
  {
    key: 4,
    name: "Italian Pizza",
    image: pizza,
    price: "  18",
    about:
      "Your choice of freshly grilled meat or sofritas wrapped in a flour tortilla with rice, beans, or fajita veggies, and topped with guac, salsa, queso blanco, sour cream or cheese. kebaniiio",
  },
  {
    key: 5,
    name: "Lebanese Pizza",
    image: pizza,
    description: "Pizza",
    price: "  12",
    about:
      "Your choice of freshly grilled meat or sofritas wrapped in a flour tortilla with rice, beans, or fajita veggies, and topped with guac, salsa, queso blanco, sour cream or cheese. kebaniiio",
  },
  {
    key: 6,
    name: "Italian Pizza",
    image: pizza,
    price: " 18",
    about:
      "Your choice of freshly grilled meat or sofritas wrapped in a flour tortilla with rice, beans, or fajita veggies, and topped with guac, salsa, queso blanco, sour cream or cheese. kebaniiio",
  },
];

export const Meat = [
  {
    key: 1,
    name: "Lebanese Meat",
    image: meat,
    description: "Meat",
    price: "12.5",
    about:
      "Your choice of freshly grilled meat or sofritas wrapped in a flour tortilla with rice, beans, or fajita veggies, and topped with guac, salsa, queso blanco, sour cream or cheese. kebaniiio",
  },
  {
    key: 2, 
    name: "Italian Meat",
    image: meat,  
    description: "Meat",                                              
    price: " 19.6",
    about:
      "Your choice of freshly grilled meat or sofritas wrapped in a flour tortilla with rice, beans, or fajita veggies, and topped with guac, salsa, queso blanco, sour cream or cheese. kebaniiio",
  },
  {
    key: 3,
    name: "Lebanese Meat",
    image: meat,
    description: "Meat",
    price: " 20",
    about:
      "Your choice of freshly grilled meat or sofritas wrapped in a flour tortilla with rice, beans, or fajita veggies, and topped with guac, salsa, queso blanco, sour cream or cheese. kebaniiio",
  },
  {
    key: 4,
    name: "Italian Meat",
    image: meat,
    price: " 19.5",
    about:
      "Your choice of freshly grilled meat or sofritas wrapped in a flour tortilla with rice, beans, or fajita veggies, and topped with guac, salsa, queso blanco, sour cream or cheese. kebaniiio",
  },
  {
    key: 5,
    name: "Lebanese Meat",
    image: meat,
    description: "Meat",
    price: " 12.33",
    about:
      "Your choice of freshly grilled meat or sofritas wrapped in a flour tortilla with rice, beans, or fajita veggies, and topped with guac, salsa, queso blanco, sour cream or cheese. kebaniiio",
  },
  {
    key: 6,
    name: "Italian Meat",
    image: meat,
    price: " 9.99 ",
    about:
      "Your choice of freshly grilled meat or sofritas wrapped in a flour tortilla with rice, beans, or fajita veggies, and topped with guac, salsa, queso blanco, sour cream or cheese. kebaniiio",
  },
];

const Menu = () => {
  const [value, setValue] =  useState(3);
  const [hover, setHover] = useState(-1);


  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const [activeButton, setActiveButton] = useState("Burgers");
  const [menu, SetMenu] = useState(Burgers);
    

  authCtx.setLogo("left");

  
  let logoposition = authCtx.Logo;

  const Cardshandler = () => {
    authCtx.settheMenu(menu);
    navigate("/menu/cards");
  };

  const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 55,
    height: 35,
    padding: 7,
    "& .MuiSwitch-switchBase": {
      margin: 1,
      padding: 0,
      transform: "translateX(6px)",
      "&.Mui-checked": {
        color: "#fff",
        transform: "translateX(22px)",
        "& .MuiSwitch-thumb:before": {
          backgroundImage: `url(${checks})`,
        },
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor: "#DB6A1B",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      backgroundColor: "#FFFFFF",
      width: 28,
      height: 28,
      "&:before": {
        content: "''",
        position: "absolute",
        width: "100%",
        height: "100%",
        left: 0,
        top: 0,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: `url(${checks})`,
      },
    },
    "& .MuiSwitch-track": {
      opacity: 1,
      backgroundColor: "#DB6A1B",
      borderRadius: 20 / 2,
    },
  }));
  const firstItemDescription = menu[0].description;
  return (
    <div className={classes.main_container}>
      <div className={classes.heading}>
       
        <div className={classes.close} onClick={() => navigate("/")}>
          <ArrowBackIcon />
        </div>
      </div>
      
      <div className={classes.menu}>
      <img className={classes.storeimages} src={storeimage}/> 
        <div className={classes.switch}>
          <Box
            sx={{
              width: 200,
              display: "flex",
              alignItems: "center",
            }}
          >
            <Rating
              name="hover-feedback"
              value={value}
              precision={0.5}
              getLabelText={getLabelText}
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
              onChangeActive={(event, newHover) => {
                setHover(newHover);
              }}
              emptyIcon={
                <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
              }
            />
            {value !== null && (
              <Box sx={{ ml: 2 }}>{labels[hover !== -1 ? hover : value]}</Box>
            )}
          </Box>

        </div>

        <div className={classes.menu_location}>
          <p>
            <PlaceIcon style={{ color: "#37A190" }} /> Location
          </p>
          <p>
            <WifiIcon style={{ color: "#37A190" }} /> Wifi
          </p>
        </div>

        <SearchBox data={menu} />
        <Swiper
          slidesPerView={4}
          spaceBetween={3}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination]}
          className={classes.mySwiper}
        >
          <SwiperSlide>
            <div
              onClick={() => {
                SetMenu(Burgers);
                setActiveButton("Burgers");
              }}
              className={
                activeButton === "Burgers"
                  ? `${classes.option_active}`
                  : `${classes.option}`
              }
            >
              <div>
                <p className={classes.title}>Burgers</p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div
              onClick={() => {
                SetMenu(Meat);
                setActiveButton("Meat");
              }}
              className={
                activeButton === "Meat"
                  ? `${classes.option_active}`
                  : `${classes.option}`
              }
            >
              <div>
                <p className={classes.title}>Meat</p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div
              onClick={() => {
                SetMenu(Pizza);
                setActiveButton("Pizza");
              }}
              className={
                activeButton === "Pizza"
                  ? `${classes.option_active}`
                  : `${classes.option}`
              }
            >
              <div>
                <p className={classes.title}>Pizza</p>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>

        <div>
          <h2 className={classes.discreptionss}>{firstItemDescription}</h2>
          <div style={{ marginTop: "-6%" }}>
            {/* {menu.map((item, index)=> ( */}
              <div >
                <article className={classes.meals} onClick={Cardshandler}>
                  <img src={menu[0].image} alt="" height={200} />
                  <h1 className={classes.meals_header}> </h1>
                </article>
              </div>
            {/* ))} */}
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default Menu;
