import React, { Fragment } from "react";
import classes from "./Contactus.module.css";
import Navbar from "../common/Header Section/Navbar";
import Footer from "../common/Footer Section/Footer";

const Contactus = () => {
  return (
    <>
      <Navbar />
      <div className={classes.contact_con}>
        <div className={classes.title}>
          <p>
            Whenever you have a{" "}
            <span style={{ fontWeight: 800 }}>question</span> or a{" "}
            <span style={{ fontWeight: 800 }}>suggestion</span> for us, we are{" "}
            <span style={{ fontWeight: 800 }}>listening</span>!
          </p>
        </div>
        <div className={classes.form}>
            <>
                <label>Name</label>
                <input type="text" placeholder="How should we address you?"/>
            </>
            <>
                <label>E-mail</label>
                <input type="text" placeholder="A corporate one is always better"/>
            </>
            <>
                <label>Company</label>
                <input type="text" placeholder="Let us know your work"/>
            </>
            <>
                <label>Phone Number</label>
                <input type="text" placeholder="Don’t worry, we don’t spam phone"/>
            </>
            <>
                <label>Message</label>
                <textarea type="textbox" rows={10} aria-multiline placeholder="Please include as much information as possible"/>
            </>
            <><button>Submit</button></>
            
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Contactus;
