import * as React from "react";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

import classes from "./Modal.module.css";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider/LocalizationProvider";
import AuthContext from "../../../../Common/auth-context";

var today = new Date(),
date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();


const Subscription = (props) => {
  
  const [Subscription, setSubscription] = React.useState(date);
  const authCtx = React.useContext(AuthContext);


  React.useEffect(() => {
    if(JSON.stringify(authCtx.clientyData) !== JSON.stringify({}) ){
      setSubscription(authCtx.accountData.SubscriptionDate)
    }}, [authCtx.clientyData]);
    
  var d = new Date(Subscription);
  var year = d.getFullYear();
  var month = d.getMonth();
  var day = d.getDate();
  var expiry = new Date((year += 1), month, day); 

 const [data, setData] = React.useState({clientUserName: "",
                                          clientPassword: "",
                                          SubscriptionDate: null,
                                          expiryDate: null,
                                           });
  React.useEffect(() => {
    setData({...data, clientUserName: props.clientUserName, clientPassword: props.clientPassword});
  }, [props]);
  

  const handleSubscription = (newValue) => {
    setSubscription(newValue);
    
  };
  React.useEffect(() => {
    setData({...data, SubscriptionDate: d.toISOString().slice(0,10), expiryDate: expiry.toISOString().slice(0,10)});
  }, [Subscription]);
React.useEffect(() => {
   authCtx.setSubsDate(d.toLocaleDateString());
    authCtx.setExpiryDate(expiry.toLocaleDateString());
    authCtx.setallClientData({...authCtx.allClientData, expiry_date: expiry.toISOString().slice(0,10), subs_date: d.toISOString().slice(0,10)});
    console.log(authCtx.allClientData);
  }, [Subscription]);
  

  React.useEffect(() => {
    props.passData(data);
  }, [data]);

  return (
    <div>
        <div className={classes.subs_dates}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack spacing={3}>
              <DesktopDatePicker
                label="Subscription Date"
                inputFormat="MM/DD/YYYY"
                value={Subscription}
                onChange={handleSubscription}
                renderInput={(params) => <TextField {...params} />}
              />
            </Stack>
            <Stack spacing={3} >
              <DesktopDatePicker
                label="Expiry Date"
                inputFormat="MM/DD/YYYY"
                value={expiry}
                renderInput={(params) => <TextField {...params} />}
              />
            </Stack>
          </LocalizationProvider>
        </div>
    </div>
  );
};

export default Subscription;
