import React from 'react'
import classes from './Categories.module.css';
import MenuGroup from './MenuGroup';


const Categories = () => {

  return (
    <div className={classes.categoriesContainer}>
        <div className={classes.categoriesHeading}>
            <p className={classes.head}>Categories</p>
            <p>Manage your categories</p>
        </div>
     <MenuGroup/>
    </div>
  )
}

export default Categories
