import * as React from "react";
import Modal from "@mui/material/Modal";

import AuthContext from "../../../../Common/auth-context";
import ChangePassword from "./ChangePassword";

const ChangePassModal = (props) => { 
  const authCtx = React.useContext(AuthContext);
  const handleClose = () => {
    authCtx.setOpenLocationModal(false);
  };
  return (
      <Modal
        open={authCtx.openLocationModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
          <ChangePassword  handleClose={handleClose}/>

      </Modal>
  );
};

export default ChangePassModal;
