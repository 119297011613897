import React from 'react'
import classes from './ItemModal.module.css';

import MenuItem from '@mui/material/MenuItem';
import Card from '../../../../Common/UI/Card/Card';
import TextField from '@mui/material/TextField';


  const itemCaregory = [
    {value: 'Burger', label: 'Burger',},
    {value: 'Pizza', label: 'Pizza', },
    {value: 'Sandwich', label: 'Sandwich', },
    {value: 'Plates', label: 'Plates', },
    {value: 'dessert', label: 'dessert', },
    {value: 'sliders', label: 'sliders', },
  ];
  const tags = [
    {value: 'Standard', label: 'Standard',},
    {value: 'New', label: 'New', },
    {value: 'Combo', label: 'Combo', },
    {value: 'Special Offer', label: 'Special Offer', },
    {value: 'outOfStock', label: 'Out Of Stock', },
  ];
  const allergies = [
    {value: 'Eggs', label: 'Eggs',},
    {value: 'Fish', label: 'Fish', },
    {value: 'Peanuts', label: 'Peanuts', },
    {value: 'Shellfish', label: 'Shellfish', },
    {value: 'Soy', label: 'Soy', },
    {value: 'Tree nuts', label: 'Tree nuts', },
    {value: 'Wheat', label: 'Wheat', },
  ];
  
  const menu = [
    { id: 1, type: "Menu", name: "Main menu", visible: true, nbOfItems: 3 },
    { id: 2, type: "Menu", name: "Combo", visible: true, nbOfItems: 3 },
    { id: 3, type: "Menu", name: "Delivery", visible: true, nbOfItems: 1 },
    { id: 3, type: "Menu", name: "Take Away", visible: true, nbOfItems: 2 },
  ];
const AddItems = () => {
  return (
    <div>
      <Card className={classes.card1}>
          <div className={classes.heading}><h3> Item Information </h3></div>
          <div className={classes.inputs_location_container}>
            <TextField
          id="name"
          label="Item Name"
          type="search"
          variant="standard"
        />    
         <TextField
          id="itemCaregory"
          select
          label="Item Caregory *"
          variant="standard"
        >
        
          {itemCaregory.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
          <TextField
          id="description"
          label="Description"
          type="search"
          variant="standard"
          className={classes.input_description}
        />  
          </div> 
        </Card>
        <Card className={classes.card1}>
          <div className={classes.heading}><h3> Item Properties </h3></div>
          <div className={classes.inputs_location_container}>
            <TextField
                    id="hasTag"
                    select
                    label="Has Tag *"
                    defaultValue={"New"}
                    variant="standard"
                    >
                    
                    {tags.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                        {option.label}
                        </MenuItem>
                    ))}
                    </TextField>
                    <TextField
                    id="allergies"
                    select
                    label="Allergies"
                    variant="standard"
                    >
                    
                    {allergies.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                        {option.label}
                        </MenuItem>
                    ))}
                    </TextField>

            <TextField
          id="price"
          label="Price"
          type="search"
          variant="standard"
        />  
          <TextField
          id="oldPrice"
          label="Old Price"
          type="search"
          variant="standard"
        />   

         {/* <TextField
          id="link"
          select
          label="Link to "
          variant="standard"
        >
          {menu.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
          </TextField>*/}
          <TextField
          id="visible"
          select
          label="Visible *"
          defaultValue="1"
          variant="standard"
        >
          
            <MenuItem value={1}>
              True
            </MenuItem>
            <MenuItem value={0}>
              False
            </MenuItem>
       
          </TextField> 
            
          </div> 
        </Card>
    </div>
  )
}

export default AddItems

