import React, { useState } from "react";
import classes from "./Register.module.css";
import Footer from "../common/Footer Section/Footer";
import Navbar from "../common/Header Section/Navbar";
import google from '../../../assets/GoogleIcon.svg'

const Register = () => {
  const [option1Checked, setOption1Checked] = useState(false);
  const [option2Checked, setOption2Checked] = useState(false);

  const handleOption1Change = () => {
    setOption1Checked(!option1Checked);
  };

  const handleOption2Change = () => {
    setOption2Checked(!option2Checked);
  };

  return (
    <>
      <Navbar />
      <div className={classes.RegisterContainer}>
        <div className={classes.title}>
          Create your <span style={{ fontWeight: 700 }}>free</span> account
        </div>
        <div className={classes.SecondContainer}>
          {/* <div className={classes.textfield}> */}
            <button className={classes.button} >
              <img src={google} alt=""  className={classes.googleimg}/>Continue with Google
            </button>
          {/* </div> */}
          <div
            style={{ display: "flex", flexDirection: "row", color: "#DB6A1B" }}
          >
            <div className={classes.border}> </div>
            <span
              style={{
                alignSelf: "end",
                marginLeft: "1%",
                marginBottom: "-0.9%",
              }}
            >
              or
            </span>
            <div className={classes.border}></div>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <label className={classes.label}> Name</label>
            <input className={classes.textfield} type="text" />

            <label className={classes.label}> Company</label>
            <input className={classes.textfield} type="text" />

            <label className={classes.label}> E-mail</label>
            <input className={classes.textfield} type="text" />

            <label className={classes.label}> Password</label>
            <input className={classes.textfield} type="password" />

            <label className={classes.label}> Confirm Password</label>
            <input className={classes.textfield} type="password" />
          </div>

          <div className={classes.radiobtn}>
            <input
              type="checkbox"
              checked={option1Checked}
              onChange={handleOption1Change}
            />{" "}
            Remember me <br />
            <input
              type="checkbox"
              checked={option2Checked}
              onChange={handleOption2Change}
            />{" "}
            I agree to the <span style={{ color: "#DB6A1B" }}>Terms</span> and{" "}
            <span style={{ color: "#DB6A1B" }} >Privacy Policy</span>
          </div>

          <div className={classes.Accounttextfield}>
            <button className={classes.CreateAccbutton}>Create Account</button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Register;
