import React, { useState, useEffect } from 'react';
import { rows } from '../Components/Admin Panel/Clients/Clients List/Clients';
import { items } from '../Components/Client Panel/Items/Items';
import { store } from '../Components/Client Panel/Stores/MultiStores';
import { catitems } from '../Components/Client Panel/Categories/MenuGroup';

const AuthContext = React.createContext({
  isLoggedIn: false,
  isMultiStore: true,
  theMenu:'',
  Logo:"",
  subsDate: "",
  expiryDate:"",
  openmodal: false,
  openside: false,
  openItemModal: false,
  openCategoryModal: false,
  openLocationModal: false,
  clientfilter: '',
  itemsfilter: '',
  catsfilter: '',
  storesfilter: '',
  addeditclienttitle: '',
  refreshdata: false,
  setrefreshdata: () => {},
  editdata: false,
  seteditdata: () => {},
  onLogout: () => {},
  onLogin: (email, password) => {},
  setMultiStore: () => {},
  settheMenu:(theMenu)=>{},
  setLogo:(Logo)=>{},
  setSubsDate:(subsDate)=>{},
  setExpiryDate: (expiryDate)=>{},
  setopenside: (openside)=> {},
  setopenmodal: (openmodal)=> {},
  setOpenItemModal: (openmodal)=> {},
  setOpenCategoryModal: (openmodal)=> {},
  setOpenLocationModal: (openmodal)=> {},
  setClientfiltered: (clientfilter)=>{},
  setItemsfiltered: (itemsfilter)=>{},
  setStoresfiltered: (storesfilter)=>{},
  setCatsfiltered: (catsfilter)=>{},
  setclienttitle: (addeditclienttitle)=>{}, 
  companyData: {},
  clientyData: {},
  accountData: {},
  categoryData: {},
  setcategoryData: (data) => {},
  allClientData: {},
  setallClientData: (data) => {},
  setCompanyData: (data) => {},
  setClientyData: (data) => {},
  setAccountData: (data) => {},
});

export const AuthContextProvider = (props) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isMultiStore, setMultiStore] = useState(true);
  const [theMenu, settheMenu] = useState(null);
  const [Logo, setLogo] = useState("");
  const [subsDate , setSubsDate] = useState('');
  const [expiryDate , setExpiryDate] = useState('');
  const [openmodal, setopenmodal] = useState(false);
  const [openside, setopenside] = useState(false);
  const [openItemModal, setOpenItemModal] = useState(false);
  const [openCategoryModal, setOpenCategoryModal] = useState(false);
  const [openLocationModal, setOpenLocationModal] = useState(false);
  const [clientfilter, setClientfiltered] = useState(rows);
  const [itemsfilter, setItemsfiltered] = useState(items);
  const [catsfilter, setCatsfiltered] = useState(catitems);
  const [storesfilter, setStoresfiltered] = useState(store);
  const [addeditclienttitle, setclienttitle] = useState('');
  const [editdata, seteditdata] = React.useState(false);
  const [refreshdata, setrefreshdata] = React.useState(false);

  useEffect(() => {
    const storedUserLoggedInInformation = localStorage.getItem('isLoggedIn');

    if (storedUserLoggedInInformation === '1') {
      setIsLoggedIn(true);
    }
  }, []);

  const logoutHandler = () => {
    localStorage.removeItem('isLoggedIn');
    setIsLoggedIn(false);
  };

  const loginHandler = () => {
    localStorage.setItem('isLoggedIn', '1');
    setIsLoggedIn(true);
  };
  
  const setMenuHandler = (theMenu) => {
    settheMenu(theMenu);
  };
  const multiStoreHandler = (multiStore) => {
    setMultiStore(multiStore);
  };

  const setLogoHandler = (Logo) => {
    setLogo(Logo);
  };

  const setSubsDateHandler = (subsDate) => {
    setSubsDate(subsDate);
  };

  const setExpiryDateHandler = (expiryDate) => {
    setSubsDate(expiryDate);
  };
  const setrefreshdataHandler = (refreshdata) => {
    setrefreshdata(refreshdata)
  };
  const seteditdataHandler = (editdata) => {
    seteditdata(editdata)
  };
  
  const opensidehandler = (openside) => {
    setopenside(openside)
  };

  const openmodalHandler = (openmodal) => {
    setopenmodal(openmodal)
  };
  const openItemModalHandler = (openItemModal) => {
    setOpenItemModal(openItemModal)
  };  
  const openCategoryModalHandler = (openCategoryModal) => {
    setOpenCategoryModal(openCategoryModal)
  };  
  const openLocationModalHandler = (openLocationModal) => {
    setOpenLocationModal(openLocationModal)
  };

  const clientfilterHandle = (clientfilter) => {
    setClientfiltered(clientfilter);
  }
  
  const itemsfilterHandle = (itemsfilter) => {
    setItemsfiltered(itemsfilter);
  }

  const storesfilterHandle = (storesfilter) => {
    setStoresfiltered(storesfilter);
  }

  const catsfilterHandle = (catsfilter) => {
    setCatsfiltered(catsfilter);
  }

  const setClientTitlehandler = (addeditclienttitle) => {
    setclienttitle(addeditclienttitle)
  }  
  const [companyData, setCompanyData] = useState({});
  const [clientyData, setClientyData] = useState({});
  const [accountData, setAccountData] = useState({});
  const [allClientData, setallClientData] = useState({});
  const [categoryData, setcategoryData] = useState({});

  const setallClientDataHandler = (data) => {
    setallClientData(data);
  };
  const setcategoryDataHandler = (data) => {
    setcategoryData(data);
  };
  const setCompanyDataHandler = (data) => {
    setCompanyData(data);
  };
  const setClientyDataHandler = (data) => {
    setClientyData(data);
  };
  const setAccountDataHandler = (data) => {
    setAccountData(data);
  };

  const contextValue = {
        isLoggedIn: isLoggedIn,
        isMultiStore: isMultiStore,
        theMenu:theMenu,
        Logo: Logo,
        subsDate: subsDate,
        expiryDate: expiryDate,
        openmodal: openmodal,
        openside: openside,
        openItemModal: openItemModal,
        openCategoryModal: openCategoryModal,
        openLocationModal: openLocationModal,
        clientfilter: clientfilter,
        itemsfilter: itemsfilter,
        storesfilter: storesfilter,
        catsfilter: catsfilter,
        addeditclienttitle: addeditclienttitle,
        refreshdata: refreshdata,
        editdata: editdata, 
        setrefreshdata: setrefreshdataHandler,
        seteditdata: seteditdataHandler,
        onLogout: logoutHandler,
        onLogin: loginHandler,
        setMultiStore: multiStoreHandler,
        settheMenu: setMenuHandler,
        setLogo: setLogoHandler,
        setSubsDate: setSubsDateHandler,
        setExpiryDate: setExpiryDateHandler,
        setopenmodal: openmodalHandler,
        setopenside: opensidehandler,
        setOpenItemModal: openItemModalHandler,
        setOpenCategoryModal: openCategoryModalHandler,
        setOpenLocationModal: openLocationModalHandler,
        setClientfiltered: clientfilterHandle,
        setItemsfiltered: itemsfilterHandle,
        setStoresfiltered: storesfilterHandle,
        setCatsfiltered: catsfilterHandle,
        setclienttitle: setClientTitlehandler,
        companyData: companyData,
        clientyData: clientyData,
        accountData: accountData,
        allClientData: allClientData,
        categoryData: categoryData,
        setallClientData: setallClientDataHandler,
        setCompanyData: setCompanyDataHandler,
        setClientyData: setClientyDataHandler,
        setAccountData: setAccountDataHandler, 
        setcategoryData: setcategoryData,
      }

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;

