import React, { useState } from 'react'
import classes from './Plans.module.css'
import PlanCard from './Plans Card/PlanCard'
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from "swiper/modules";

// import required modules

const monthlyarray = [
  {
    price: '19',
    mostpop: false,
    type: "monthly",   
  },
  {
    price: '59',
    mostpop: true,
    type: "monthly",
  },
  {
    price: '39',
    mostpop: false,
    type: "monthly",
  },
  
]


const yearlyarray = [
  {
    price: '199',
    mostpop: false,
    type: "yearly",   
  },
  {
    price: '499',
    mostpop: true,
    type: "yearly",
  },
  {
    price: '399',
    mostpop: false,
    type: "yearly",
  },
 
]

const Plans = () => {
  const [monthly,setMonthly] = useState(true)

  const change = () =>{
    setMonthly(!monthly)
  }

  return (
    <div className={classes.plans_container}>
      <div className={classes.plans_content}>
        <div className={classes.title_container}>
            <div className={classes.text}>
          <h1>Plans & Pricing</h1>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
          </div>
          <div className={classes.btns}>
            <button className={`${monthly ? classes.unclickedbtn : classes.clickedbtn }`} onClick={change}>Monthly</button>
            <button className={`${monthly ? classes.clickedbtn : classes.unclickedbtn }`} onClick={change}>Yearly</button>
          </div>
        </div>
        <div className={classes.card_container}>
        <Swiper
        spaceBetween={30}
        pagination={true}
        modules={[Pagination]}
        breakpoints={{
          0: {
            slidesPerView: 1,
            },
          651: {
            slidesPerView: 2,
            },
          951: {
            slidesPerView: 3,
          },
      }}
      style={{
        padding: '3%'
      }}
      >
        {monthly?
        <>
        {monthlyarray.map((data) => {
          return(
        <SwiperSlide style={{padding: '0%', borderRadius: '30px'}}><PlanCard  price={data.price} mostpop={data.mostpop} type={data.type}/></SwiperSlide>
          )
        })}</> 
        : 
        <>
        {yearlyarray.map((data) => {
          return(
        <SwiperSlide style={{padding: '0%', borderRadius: '30px'}}><PlanCard  price={data.price} mostpop={data.mostpop} type={data.type}/></SwiperSlide>
          )
        })}</> }
      </Swiper>
        </div>
        </div>
        </div>
  )
}

export default Plans