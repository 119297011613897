import React from 'react'
import classes from './PlanCard.module.css'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const PlanCard = (props) => {
  return (
    <div className={`${props.mostpop ? classes.card_con_mostpop : classes.card_con}`}>
        <div className={classes.card_content}>
            {props.mostpop && <div className={classes.mostpopular}><p>MOST POPULAR</p></div>}
            <h1>${props.price}<span style={{fontWeight: 200, fontSize: 20}}>/{props.type}</span></h1>
            <h2>Starter</h2>
            <p>Unleash the power of automation.</p>
            <p><CheckCircleIcon/>Multi-step Zaps<br/>
            <CheckCircleIcon/>Multi-step Zaps<br/>
            <CheckCircleIcon/>Multi-step Zaps</p>
            <button>Choose plan</button>
        </div>
    </div>
  )
}

export default PlanCard