import { useState, useContext } from 'react';
import classes from './Store.module.css';

import StoreInformations from './StoreInformations';
import Profile from './Profile';
import Contact from './Contact';
import Location from './Location';
import EditIcon from '@mui/icons-material/Edit';
import MultiLocation from './MultiLocation';
import MultiStores from './MultiStores';
import AuthContext from '../../../Common/auth-context';







const Stores = () => {
   const authCtx = useContext(AuthContext);
   const [multiLocation, setMultiLocation] = useState(false);
       return (
         <>
         
            {!authCtx.companyData.multiStore && <div className={classes.storeContainer}>
               <div className={classes.image_container}> 
               <div className={classes.logo_container}><div className={classes.logo_edit}></div></div>
               <EditIcon className={classes.edit_icon}/></div>
               <StoreInformations/>
               {/* <Profile/> */}
               {/* {multiLocation &&  <Location/>} */}
               {/* { !multiLocation && <MultiLocation/>} */}
               <Contact/>       
               </div>}
           {authCtx.companyData.multiStore && <MultiStores/>}
           {!authCtx.companyData.multiStore && <div className={classes.btns}>
                                       
                                       <button className={classes.btnsave} >
                                       Save
                                      </button>
                                       <button className={classes.btncancel} onClick={()=>authCtx.setMultiStore(true)}>
                                       Discard
                                       </button>
                                    </div>}
        </>
       );
    };
    
     export default Stores;
