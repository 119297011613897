import React, { useContext, useEffect, useState } from 'react'
import Card from '../../../../Common/UI/Card/Card'
import TextField from "@mui/material/TextField";
import { MuiTelInput } from 'mui-tel-input'
import classes from './Modal.module.css'
import AuthContext from "../../../../Common/auth-context";

const ClientInformation = (props) => {
  const authCtx = useContext(AuthContext);

  const {store_name, client_name, address,phone}= props;
  const [companyName, setcompanyName] = useState(authCtx.clientyData.companyName)
  const [clientName, setclientName] = useState(authCtx.clientyData.companyName)
  const [phonee, setPhone] = useState('+961')
  const [clientAddress, setclientAddress] = useState(authCtx.clientyData.companyName)
  const [data, setData] = useState({companyName: "",
                                    clientName: "",
                                    clientPhone: "",
                                   });

  useEffect(() => {
    if(JSON.stringify(authCtx.allClientData) !== JSON.stringify({}) ){
      setcompanyName(authCtx.allClientData.store_name);
      setclientName(authCtx.allClientData.client_name);
      setPhone(authCtx.allClientData.phonenumber);
      setclientAddress(authCtx.allClientData.location);

    }}, [authCtx.allClientData]);

  useEffect(() => {
    props.onData(data);
  }, [data]);
  
  return (
    <div>
        <Card className={classes.card1}>
              <div className={classes.heading}>
                <h3> Client Information</h3>
              </div>
             <div className={classes.inputs_container}>
                <TextField
                  id="company-name"
                  label="Company Name"
                  value={companyName}
                  type="search"
                  variant="standard"
                  onChange={(e)=>{
                    authCtx.setallClientData({...authCtx.allClientData,store_name: e.target.value})
                    setcompanyName(e.target.value);
                    setData({...data, companyName:e.target.value });}}
                  required={props.isRequired}
                ></TextField>
                <TextField
                  id="client-name"
                  label="Client Name"
                  variant="standard"
                  value={clientName}
                  onChange={(e)=>{
                    authCtx.setallClientData({...authCtx.allClientData,client_name: e.target.value})
                    setclientName(e.target.value);
                    setData({...data, clientName:e.target.value });}}
                  required={props.isRequired}
                />
                  <MuiTelInput
                  id="client-phone"
                  label="Client Phone" 
                  variant="standard"
                  value={phonee}
                  onChange={(newPhone) => {
                    authCtx.setallClientData({...authCtx.allClientData,phonenumber: newPhone})
                                          setPhone(newPhone);
                                           setData({...data, clientPhone: newPhone });
                                        }}
                  required={props.isRequired}
                  />
                   <TextField
                  id="client-address" 
                  label="Client Address"
                  variant="standard"
                  value={clientAddress}
                  onChange={(e)=>{
                    authCtx.setallClientData({...authCtx.allClientData, location: e.target.value})
                    setclientAddress(e.target.value)
                    setData({...data, clientAddress:e.target.value });}}
                  required={props.isRequired}
                  />
              </div> 
            </Card>
    </div>
  )
}

export default ClientInformation;