import * as React from "react";
import Modal from "@mui/material/Modal";
import axios from 'axios';
import classes from "./CategoryModal.module.css";
import AuthContext from "../../../../Common/auth-context";
import AddCategory from "./AddCategory";

const CategoryModal = (props) => {
  const { lable, handleClose } = props;
  const [inputsRequired, setInputsRequired] = React.useState(true);

  const [data, setData] = React.useState({});

  const axiosInstance = axios.create({
    baseURL: '//localhost:3001/augmenu/menuCat',
  });
  const axiosInstance1 = axios.create({
    baseURL: '//localhost:3001/augmenu/itemCat',
  });
  const saveHandler = (event) => {
    event.preventDefault();
    if (data.Type === "menu") {
      // axiosInstance.post('/add', {MenuName:data.Name , StoreID: 1, Image:"", Visible:data.Visible})
      // .then(response => {
      //   console.log(response);
      
      // })
      // .catch(error => {
      //   console.log(error);
      // });     
console.log("test");
     console.log(data);
     //props.handleClose();
};
    

    if (data.Type === "item") {
      axiosInstance1.post('/add', {MenuCartID:data.Link, Name:data.Name, Image:"", Visible:data.Visible})
      .then(response => {
        console.log(response);
      
      })
      .catch(error => {
        console.log(error);
      }); 
      console.log("item")
    }
  }

  const authCtx = React.useContext(AuthContext);
  // console.log(client_name);

  const handleData = (data) => {
    setData(data);
  }
  return (
    
      <Modal
        open={authCtx.openCategoryModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className={classes.maincontainer}>
          <div className={classes.mainheader}>
            <p className={classes.mainhead}>Category</p>
            <p>{lable}</p>
          </div>
          <form onSubmit={saveHandler}>
          <div className={classes.storecontainer}>
            <AddCategory onData={handleData} isRequired={inputsRequired} menuData={props.menuData}/>
          </div>
          <div className={classes.btns}>
            <button className={classes.btncancel} onClick={props.handleClose}>
              Cancel
            </button>
            <button className={classes.btnsave} type="submit">
              Save
            </button>
          </div>
          <div className={classes.btns_mini}>
            <button className={classes.btnsave} type="submit">
              Save
            </button>
            <button className={classes.btncancel} onClick={props.handleClose}>
              Cancel
            </button>
          </div>
          </form>
        </div>
      </Modal>
  );
};

export default CategoryModal;
