import {React , useState} from 'react'
import classes from './Store.module.css';
import Card from '../../../Common/UI/Card/Card';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import Groups2Icon from '@mui/icons-material/Groups2';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import WebAssetIcon from '@mui/icons-material/WebAsset';

import {BiSolidDownArrow} from 'react-icons/bi'
import {BiSolidUpArrow} from 'react-icons/bi'


function Contact() {

  const [isVisible, setIsVisible] = useState(true);

  const toggleVisibility = () => {
    setIsVisible(prev => !prev);
  };
   
  return (
    <div style={{width:'90%',paddingTop:'3%'}}>
      <div className={classes.heading}><h3> Social Contacts </h3><button className={classes.minimizewbtn} onClick={toggleVisibility}>
          {isVisible ? <BiSolidDownArrow/> :<BiSolidUpArrow/>}
        </button></div>
    <Card className={classes.card1}>
    
     
        {!isVisible && (
    <div className={classes.sotialmedia_container}>  

    <Box className={classes.textfieldss} sx={{ display: 'flex', alignItems: 'center' , height:'60px',paddingLeft:'5%'}}>
  <FacebookIcon sx={{ color: '#DB6A1B', mr: 1, my: 0.5 }} />
  <TextField  id="Facebook"   variant="standard" InputProps={{
      disableUnderline: true,
    }} placeholder='Facebook'/>
</Box>

<Box  className={classes.textfieldss}  sx={{ display: 'flex', alignItems: 'center' , height:'60px' ,paddingLeft:'5%'}}>
  <InstagramIcon sx={{ color: '#DB6A1B', mr: 1, my: 0.5 }} />
  <TextField   id="Instagram" placeholder="Instagram" variant="standard" InputProps={{
      disableUnderline: true,
    }} />
</Box>

<Box className={classes.textfieldss} sx={{ display: 'flex', alignItems: 'center', height:'60px' ,paddingLeft:'5%'}}>
  <WhatsAppIcon sx={{ color: '#DB6A1B', mr: 1, my: 0.5 }} />
  <TextField id="WhatsApp" placeholder="WhatsApp" variant="standard" InputProps={{
      disableUnderline: true,
    }} />
</Box>

<Box className={classes.textfieldss}  sx={{ display: 'flex', alignItems: 'center', height:'60px' ,paddingLeft:'5%'}}>
  <YouTubeIcon sx={{ color: '#DB6A1B', mr: 1, my: 0.5 }} />
  <TextField id="Youtube" placeholder="Youtube" variant="standard" InputProps={{
      disableUnderline: true,
    }}/>
</Box>

<Box  className={classes.textfieldss}  sx={{ display: 'flex', alignItems: 'center' , height:'60px',paddingLeft:'5%'}}>
  <PhoneIcon sx={{ color: '#DB6A1B', mr: 1, my: 0.5 }} />
  <TextField id="phone" placeholder="Phone" variant="standard" InputProps={{
      disableUnderline: true,
    }} />
</Box>

<Box className={classes.textfieldss} sx={{ display: 'flex', alignItems: 'center' , height:'60px',paddingLeft:'5%'}}>
  <WebAssetIcon sx={{ color: '#DB6A1B', mr: 1, my: 0.5 }} />
  <TextField id="website" placeholder="Website" variant="standard" InputProps={{
      disableUnderline: true,
    }}/>
</Box> 

</div>)} </Card>   </div>
  )
}


  

export default Contact
