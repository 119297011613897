import React, { useState, useEffect, useRef , useContext } from 'react';
import classes from "./SideBar.module.css";
import AuthContext from "../../../Common/auth-context";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";
import StoreMallDirectoryIcon from "@mui/icons-material/StoreMallDirectory";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import LogoutIcon from "@mui/icons-material/Logout";
import Profile from "../Profile/Profile";
import Stores from "../Stores/Stores";
import { Link } from "react-router-dom";
import Categories from "../Categories/Categories";
import Items from "../Items/Items";
import QRCode from "../QRCode/QRCode";
import Logo from "../../../assets/Logo.png";
import ListSubheader from "@mui/material/ListSubheader";
// import List from '@mui/material/List';
// import ListItemButton from '@mui/material/ListItemButton';
// import ListItemIcon from '@mui/material/ListItemIcon';
// import ListItemText from '@mui/material/ListItemText';
import Collapse from "@mui/material/Collapse";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import DraftsIcon from "@mui/icons-material/Drafts";
import SendIcon from "@mui/icons-material/Send";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import StarBorder from "@mui/icons-material/StarBorder";

const drawerWidth = 240;



const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});


const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 6),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer ,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  background: "rgba(55, 161, 144, 0.47)",
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const SideBar = (props) => {
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const authCtx = useContext(AuthContext);
  const [menuOption, setMenuOption] = useState("profile");
  const [stores, setStores] = useState("");

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [opened, setOpened] = useState(false);

  const handleClick = () => {
    setOpened(!opened);
  };

  const [isRightArrow, setIsRightArrow] = useState(true);

  const toggleArrow = () => {
    setIsRightArrow(!isRightArrow);
  }

  const listRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (listRef.current && !listRef.current.contains(event.target)) {
        setOpened(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);



  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="absolute" open={open}>
        <Toolbar className={classes.topbar}>
         
          {!open && (
            <Typography variant="h6" noWrap component="div">
              <img style={{ width: "65%", marginLeft:'35%' }}  src={Logo} alt="" />
            </Typography>
          )}            
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        < DrawerHeader className={classes.sidebartop}>
          <Typography variant="h6" noWrap component="div">
          {open && <img src={Logo} alt="" style={{ width: "65%"  }} />}
          </Typography>
        </DrawerHeader>
       
  
        <List style={{paddingTop:'105px'}} className={classes.sidebartop1}>
        <IconButton 
      color="inherit"
      
      aria-label="toggle drawer"
      onClick={open ? handleDrawerClose : handleDrawerOpen}
      edge="end"
      sx={{
        marginLeft: 4,
        
         
      }}
    >
      {open ? (
        <ChevronLeftIcon  className={classes.closeicon}/>
      ) : (
        <ChevronRightIcon className={classes.closeicon}/>
      )}
    </IconButton>
          <div
            style={{ marginLeft: "10%" }}
            onClick={() => {
              setMenuOption("profile");
            }}
            className={menuOption === "profile" ? `${classes.overlay}` : ""}
          >
            <ListItem
              disablePadding
              sx={{ display: "block" }}
              className={menuOption === "profile" ? `${classes.overlay}` : ""}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  // justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <AccountBoxIcon className={classes.icons} />
                </ListItemIcon>
                <ListItemText
                  primary="Profile"
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          </div>

          <List
           ref={listRef}
            sx={{ bgcolor: "transperant", marginLeft: "11%" }}
            component="nav"
            aria-labelledby="nested-list-subheader"
          >



            <ListItemButton 
                onClick={() => {
                  handleClick();
                  setMenuOption("stores"); 
                }}
                style={{
                  
                  borderRadius: '18px',width:'80%'}}
                className={
                  menuOption === "stores" ? `${classes.overlay}` : ""
                  
                }
                >
              <StoreMallDirectoryIcon />
              <ListItemText
                primary="Stores"
                sx={{ marginLeft: "12%" , opacity: open ? 1 : 0 }}
              />
              
              {opened ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={opened} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText
                    primary="Store1"
                    onClick={() => {
                      setStores("store1"); 
                    }}
                    className={
                      stores === "store1" ? `${classes.overlay1}` : ""
                    }
                  />
                </ListItemButton>
              </List>
            </Collapse>
            <Collapse in={opened} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText
                    primary="Store2"
                    onClick={() => {
                      setStores("store2"); 
                    }}
                    className={
                      stores === "store2" ? `${classes.overlay1}` : ""
                    }
                  />
                </ListItemButton>
              </List>
            </Collapse>
            <Collapse in={opened} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText
                    primary="Store3"
                    onClick={() => {
                      setStores("store3"); 
                    }}
                    className={
                      stores === "store3" ? `${classes.overlay1}` : ""
                    }
                  />
                </ListItemButton>
              </List>
            </Collapse>
          </List>
                 
          <div
            style={{ marginLeft: "10%" }}
            onClick={() => {
              setMenuOption("categories");
            }}
            className={menuOption === "categories" ? `${classes.overlay}` : ""}
          >
            <ListItem
              disablePadding
              sx={{ display: "block" }}
              className={
                menuOption === "categories" ? `${classes.overlay1}` : ""
              }
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  // justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <MenuBookIcon className={classes.icons} />
                </ListItemIcon>
                <ListItemText
                  primary="Categories"
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          </div>
          <div
            style={{ marginLeft: "10%" }}
            onClick={() => {
              setMenuOption("items");
            }}
            className={menuOption === "items" ? `${classes.overlay}` : ""}
          >
            <ListItem
              disablePadding
              sx={{ display: "block" }}
              className={menuOption === "items" ? `${classes.overlay1}` : ""}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  // justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <SpeakerNotesIcon className={classes.icons} />
                </ListItemIcon>
                <ListItemText primary="Items" sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          </div>
          <div
            style={{ marginLeft: "10%" }}
            onClick={() => {
              setMenuOption("QRcode");
            }}
            className={menuOption === "QRcode" ? `${classes.overlay}` : ""}
          >
            <ListItem
              disablePadding
              sx={{ display: "block" }}
              className={menuOption === "QRcode" ? `${classes.overlay1}` : ""}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  // justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <QrCode2Icon className={classes.icons} />
                </ListItemIcon>
                <ListItemText
                  primary="QR codes"
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          </div>
        </List>
       
        <List className={classes.sidebar}>
          
          <div
            style={{ marginLeft: "10%" }}
            onClick={() => {
              setMenuOption("settings");
            }}
            className={menuOption === "settings" ? `${classes.overlay}` : ""}
          >
           
          </div>
          <Link to="/login">
            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                style={{ marginLeft: "10%" , paddingTop:"30%" }}
                sx={{
                  minHeight: 48,
                  // justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                    
                  }}
                >
                  
                  <LogoutIcon className={classes.icons} />
                </ListItemIcon>
                <ListItemText  
                  primary="Sign out"
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          </Link>
        </List>
      </Drawer>
      <Box sx={{ flexGrow: 1 }}>
        {menuOption === "profile" && <Profile />}
        {menuOption === "stores" && <Stores />}
        {menuOption === "categories" && <Categories />}
        {menuOption === "items" && <Items />}
        {menuOption === "QRcode" && <QRCode />}
      </Box>
    </Box>
  );
};
export default SideBar;
