import * as React from "react";
import Modal from "@mui/material/Modal";
import axios from 'axios';

import classes from "./Modal.module.css";
import ClientAccount from "./ClientAccount";
import ClientInformation from "./ClientInformation";
import CompanySettings from "./CompanySettings";
import AuthContext from "../../../../Common/auth-context";

const AddModal = (props) => {
  const { open, store_name, client_name, address,phone, handleClose } = props;
  const authCtx = React.useContext(AuthContext);
  const [companyData, setCompanyData] = React.useState({});
  const [clientyData, setClientyData] = React.useState({});
  const [accountData, setAccountData] = React.useState({});
  const [StoreData, setStoreData] = React.useState({IsActive: true,});

  const [inputsRequired, setInputsRequired] = React.useState(true);
  const handleCompanyData = (data) => {

    setCompanyData(data);
  }
  const handleClientData = (data) => {
    setClientyData(data);
  }
  const handleAccountData = (data) => {
    setAccountData(data);
  }

  
  // React.useEffect(() => {
  //   // setStoreData({...StoreData ,...clientyData,});
  //   console.log(StoreData);
  // }, []);

  React.useEffect(() => {
    setStoreData({...StoreData , ...companyData, ...accountData, ...clientyData,});
    console.log(StoreData);
  }, [companyData,clientyData ]);

  // React.useEffect(() => {
  //   
  //   console.log(StoreData);
  // }, [accountData]);

  const axiosInstance = axios.create({
    baseURL: '//localhost:3001/augmenu/clients',
  });

  const saveHandler = (event) => {
    event.preventDefault();
     if(!authCtx.editdata)
    {  
       authCtx.setCompanyData(companyData);
       authCtx.setClientyData(clientyData);
       authCtx.setAccountData(accountData);    
        console.log(StoreData);
           axiosInstance.post('/add', StoreData)
               .then(response => {
                 console.log(response);
               
               })
               .catch(error => {
                 console.log(error);
               });
              console.log(StoreData);
              authCtx.setrefreshdata(true);
              props.handleClose();
        };
     if(authCtx.editdata)
    {
      axiosInstance.post('/update', authCtx.allClientData)
               .then(response => {
                 console.log(response);
               })
               .catch(error => {
                 console.log(error);
               });
      authCtx.setrefreshdata(true);
              console.log(authCtx.allClientData);
              props.handleClose();
    };
  
        
  }
   const handleSubmit = (event) => {
    event.preventDefault();
console.log("testtt")  };

  return (
    <div>
      <Modal
        open={authCtx.openmodal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className={classes.maincontainer}>
          <div className={classes.mainheader}>
            <p className={classes.mainhead}>Clients</p>
            <p>{authCtx.addeditclienttitle}</p>
          </div>
          <form onSubmit={saveHandler}>
          <div className={classes.storecontainer}>
            <ClientInformation
              store_name={store_name}
              client_name={client_name}
              address={address}
              phone={phone}
              onData={handleClientData}
              isRequired={inputsRequired}
            />
            <ClientAccount onData={handleAccountData} isRequired={inputsRequired}/>
          </div>
          <CompanySettings onData={handleCompanyData} isRequired={inputsRequired}/>
          <div className={classes.btns}>
            <button className={classes.btncancel} onClick={props.handleClose}>
              Cancel
            </button>
            <button className={classes.btnsave} type="submit">
              Save
            </button>
          </div>
          <div className={classes.btns_mini}>
            <button className={classes.btnsave} type="submit">
              Save
            </button>
            <button className={classes.btncancel} onClick={props.handleClose}>
              Cancel
            </button>
          </div> 
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default AddModal;
