import React, { useContext } from "react";
import TextField from "@mui/material/TextField";
import Card from "../../../Common/UI/Card/Card";
import AuthContext from "../../../Common/auth-context";
import classes from "./Profile.module.css";
import Subscription from "./Subcription";
import CompanySettings from "./CompanySettings";

const ClientAccount = () => {
  const authCtx = useContext(AuthContext);
  // console.log(authCtx.companyData);
  // console.log(authCtx.clientyData);
  // console.log(authCtx.accountData);
  return (
    
    <div>
       <div className={classes.heading}>
        <h2> Profile </h2>
         
        <div>
          {/* <Subscription /> */}
        </div>
      </div>
    <Card className={classes.card1}>
     
      <div className={classes.inputs_container1}>
        <div className={classes.info}>
                <input type="text" placeholder="Company name" className={classes.inputext}/>
          <p>{authCtx.clientyData.companyName}</p>
        </div>
        <div className={classes.info}>
        <input type="text" placeholder="Subscription Date" className={classes.inputext}/>
          <p>{authCtx.accountData.SubscriptionDate}</p>
        </div>
        <div className={classes.info}>
        <input type="text" placeholder="Expiry Date" className={classes.inputext}/>
          <p>{authCtx.accountData.expiryDate}</p>
        </div>
      </div>
      <CompanySettings />
    </Card></div>
  );
};

export default ClientAccount;
