import React, { useState } from 'react'
import Header from './Header Section/Header'
import About from './About Section/About'
import Clients from './Clients/Clients'
import Plans from './Plans Section/Plans'
import Footer from '../common/Footer Section/Footer'
import Sidebar from '../common/Header Section/Side Bar/Sidebar'

const Landingpage = ({toggle, isOpen}) => {
  return (
    <div>
        <Sidebar isOpen={isOpen} toggle={toggle}/>
        <Header toggle={toggle}/>
        <About />
        <Clients />
        <Plans />
        <Footer />
    </div>
  )
}

export default Landingpage