import React from 'react';
import "./footer.css";
import {FaCreativeCommonsBy, FaFacebookSquare, FaInstagram} from 'react-icons/fa';
import {BsTwitter} from 'react-icons/bs';
import {FaXTwitter} from 'react-icons/fa6'
import Logo from '../../../assets/Logo.png'
import footerabs from '../../../assets/footerabs.png'


function Footer() {
  return (
    <footer>
      <a href='#' className='footer__logo'> Digital Menu </a>

      <div className='footer__socials'>
      <a href='https://facebook.com'  target='_blank'> <FaFacebookSquare/></a>
      <a href='https://instagram.com' target='_blank'> <FaInstagram/></a>
      <a href='https://twitter.com'   target='_blank'><FaXTwitter/></a>
      </div>
       <div><img src={footerabs} style={{width:'22%', display:'flex',justifyContent:'flex-end',marginBottom:'-50px'}}/></div>
      <div style={{display:'flex', flexDirection:'row' , columnGap:'30px',zIndex:'100'}}>
        <p>Powered by <img className='logoplavi' src ={Logo}/></p>
        <p>Privacy Policy</p>
        <p>Terms and Conditions</p>
      </div>   
    </footer>
  )
}

export default Footer;