import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../../Common/auth-context";
import classes from "./Cards.module.css";
import Logo from "../../../assets/MetaribaBlueLogo.png";
import PlaceIcon from "@mui/icons-material/Place";
import PhoneIcon from "@mui/icons-material/Phone";
import WifiIcon from "@mui/icons-material/Wifi";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Footer from "../FooterSection";
import storeimage from '../../../assets/storeimage.png'
import Switch from "@mui/material/Switch";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import checks from "../../../assets/checks.png"
import FormControlLabel from "@mui/material/FormControlLabel";

const Cards = () => {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);

  const [currency, setCurrency] =  useState("USD");

  let logoposition = authCtx.Logo;
  const [cart, setCart] = useState(
    authCtx.theMenu.map(item => ({ quantity: 1, price: item.price }))
  );

   


  const addhandler = (index) => {
    setCart(prevCart => {
      const newCart = [...prevCart];
      newCart[index].quantity += 1;
      return newCart;
    });
  };
  
  const removehandler = (index) => {
    setCart(prevCart => {
      const newCart = [...prevCart];
      newCart[index].quantity = Math.max(0, newCart[index].quantity - 1);
      return newCart;
    });
  };

  const backhandler = () => {
    navigate("/menu");
  };

  const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 55,
    height: 35,
    padding: 7,
    "& .MuiSwitch-switchBase": {
      margin: 1,
      padding: 0,
      transform: "translateX(6px)",
      "&.Mui-checked": {
        color: "#fff",
        transform: "translateX(22px)",
        "& .MuiSwitch-thumb:before": {
          backgroundImage: `url(${checks})`,
        },
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor: "#DB6A1B",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      backgroundColor: "#FFFFFF",
      width: 28,
      height: 28,
      "&:before": {
        content: "''",
        position: "absolute",
        width: "100%",
        height: "100%",
        left: 0,
        top: 0,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: `url(${checks})`,
      },
    },
    "& .MuiSwitch-track": {
      opacity: 1,
      backgroundColor: "#DB6A1B",
      borderRadius: 20 / 2,
    },
  }));



  const getConvertedPrice = (price) => {
    if (currency === "LBP") {
      return `LBP ${(parseFloat(price.slice(1)) *15000 ).toFixed(0)} `;
    }
    return price;
  };

  return (
    <div className={classes.main_container}>
      <div className={classes.heading}>
        <div className={classes.close} onClick={backhandler}>
          <ArrowBackIcon />
        </div>
      </div>
      <div className={classes.menu}>
      <div className={classes.switch}>
        <FormControlLabel
          control={
            <>
              <p>USD</p>
              <MaterialUISwitch
                sx={{ m: 1 }}
                defaultChecked={currency === "USD" ? false : true}
                onChange={() => setCurrency(prevCurrency => prevCurrency === "USD" ? "LBP" : "USD")}
              />
              <p>LBP</p>
            </>
          }
        />
      </div>
      <img className={classes.storeimages} src={storeimage}/> 
        <h1 className={classes.menu_header}>Digital Menu</h1>
        <div className={classes.menu_location}>
          <p>
            <PlaceIcon style={{ color:"#37A190"}} /> Digital-menu
          </p>
          <p>
            <WifiIcon style={{ color:"#37A190"}} /> Digital-menu
          </p>
        </div>
        {authCtx.theMenu.map((item, index) => {
            const itemInCart = cart[index];
            const totalPrice = itemInCart.quantity * parseFloat(itemInCart.price.slice(1));

          return (
            <div className={classes.meals_list} key={index}>
              <img src={item.image} alt="" />
              <div>  
                <h1 className={classes.meals_list_name}>{item.name}</h1>
                <h5 className={classes.meals_list_content}>{item.about}</h5>
              </div>

                  <h3 className={classes.meals_list_price}>
        {getConvertedPrice(`$ ${totalPrice.toFixed(2)}`)} 
      </h3>
      <div
  className={classes.meals_list_add}
  onClick={() => addhandler(index)}
>
  <AddCircleOutlineIcon />
</div>
{cart[index].quantity === 0 ? (
  "" 
) : (
  <div
    className={classes.meals_list_remove}
    onClick={() => removehandler(index)}
  >
    <RemoveCircleOutlineIcon style={{ marginRight: "8px" }} />
    {cart[index].quantity}
  </div>
              )}
            </div>
          );
        })}
       <Footer />

      </div>
    </div>
  );
};

export default Cards;
