import React, { useContext, useEffect, useState } from "react";
import classes from "./Categories.module.css";
import AuthContext from "../../../Common/auth-context";

import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import CheckIcon from "@mui/icons-material/Check";

import TextField from "@mui/material/TextField";
import CategoryModal from "./Category Modal/CategoryModal";
import CategoriesSearchBox from "./Categories Search/CategoriesSearch";

const menu = [
  { id: 1, type: "Menu", name: "Main menu", visible: true, nbOfItems: 3 },
  { id: 2, type: "Menu", name: "Combo", visible: true, nbOfItems: 3 },
  { id: 3, type: "Menu", name: "Delivery", visible: true, nbOfItems: 1 },
  { id: 3, type: "Menu", name: "Take Away", visible: true, nbOfItems: 2 },
];

export const catitems = [
  {
    id: 1,
    menuid: 1,
    type: "Item",
    name: "Burger",
    visible: true,
    nbOfItems: 0,
  },
  {
    id: 2,
    menuid: 1,
    type: "Item",
    name: "Pizza",
    visible: false,
    nbOfItems: 0,
  },
  {
    id: 3,
    menuid: 1,
    type: "Item",
    name: "Sandwich",
    visible: true,
    nbOfItems: 0,
  },
  {
    id: 4,
    menuid: 1,
    type: "Item",
    name: "Plates",
    visible: false,
    nbOfItems: 0,
  },
  {
    id: 5,
    menuid: 1,
    type: "Item",
    name: "dessert",
    visible: true,
    nbOfItems: 0,
  },
  {
    id: 6,
    menuid: 2,
    type: "Item",
    name: "Burger",
    visible: true,
    nbOfItems: 0,
  },
  {
    id: 7,
    menuid: 2,
    type: "Item",
    name: "Pizza",
    visible: false,
    nbOfItems: 0,
  },
  {
    id: 8,
    menuid: 2,
    type: "Item",
    name: "Sandwich",
    visible: false,
    nbOfItems: 0,
  },
  {
    id: 9,
    menuid: 3,
    type: "Item",
    name: "sliders",
    visible: false,
    nbOfItems: 0,
  },
  {
    id: 10,
    menuid: 4,
    type: "Item",
    name: "Plates",
    visible: false,
    nbOfItems: 0,
  },
  {
    id: 11,
    menuid: 4,
    type: "Item",
    name: "sliders",
    visible: true,
    nbOfItems: 0,
  },
];

const headCells = [
  {
    id: "type",
    label: "Type",
  },
  {
    id: "name",
    label: "Name",
  },
  {
    id: "linkk",
    label: "Link",
  },
  {
    id: "nbOfItems",
    label: "Number of Items",
  },
  {
    id: "visible",
    label: "Visible",
  },
];

function EnhancedTableHead(props) {

  return (
    <TableHead>
      <TableRow className={classes.headingRow}>
        {headCells.map((headCell) => (
          <TableCell className={classes.headingRow} key={headCell.id}>
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;
  const authCtx = useContext(AuthContext);
  const [openModule, setopenModule] = useState("Add Category");

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
      className={classes.tableHeading}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Categories
        </Typography>
      )}

      <CategoriesSearchBox data={catitems} />

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
      <AddCircleIcon
        onClick={() => {
          setopenModule("adddd");
          authCtx.setOpenCategoryModal(true);
          console.log("addddd");
        }}
      />
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const MenuGroup = () => {
  const [selected, setSelected] = useState([]);
  const [menuData, setmenuData] = useState([]);
  const [itemData, setitemData] = useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [httpError, setHttpError] = React.useState();
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const authCtx = useContext(AuthContext);
  const [openModule, setopenModule] = useState("Add Category");
  const numSelected = selected.length;
  const handleClose = () => {
    authCtx.setOpenCategoryModal(false);
  };

  useEffect(() =>  {
    const fetchData = async () => {
      const menuResponse = await fetch("//localhost:3001/augmenu/menucat/all")
      const itemResponse = await fetch("//localhost:3001/augmenu/itemCat/all")
      if (!menuResponse.ok) {
        throw new Error('Something went wrong!');
      }
      if (!itemResponse.ok) {
        throw new Error('Something went wrong!');
      }
      const menuResponseData = await menuResponse.json();
      const itemResponseData = await itemResponse.json();
    
      const loadedData = [];
      const loadeditemData = [];
  
      for (const key in menuResponseData) {
        if (menuResponseData[key].StoreID === 1){
        loadedData.push({
          id: menuResponseData[key].ID,
          StoreID: menuResponseData[key].StoreID,
          MenuName: menuResponseData[key].MenuName,
          Image: menuResponseData[key].Image,
          Visible: menuResponseData[key].Visible,
        });}
      }
      for (const key in itemResponseData) {
        loadeditemData.push({
          id: itemResponseData[key].ID,
          MenuCartID: itemResponseData[key].MenuCartID,
          Name: itemResponseData[key].Name,
          Image: itemResponseData[key].Image,
          Visible: itemResponseData[key].Visible,
        });
      }
      setmenuData(loadedData);
      setitemData(loadeditemData);      
      setIsLoading(false);
    }
    fetchData().catch((error) => {
      setIsLoading(false);
      setHttpError(error.message);
    });
    console.log(menuData)
    
  }, []);
  

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - menuData.length) : 0;

    if (isLoading) {
      return (
        <section className={classes.MealsLoading}>
          <p>Loading...</p>
        </section>
      );
    }
  
    if (httpError) {
      return (
        <section className={classes.MealsError}>
          <p>{httpError}</p>
        </section>
      );
    }
  

  return (
    <div className={classes.menugroupContainer}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <Toolbar
          sx={{
            pl: { sm: 2 },
            pr: { xs: 1, sm: 1 },
            ...(numSelected > 0 && {
              bgcolor: (theme) =>
                alpha(
                  theme.palette.primary.main,
                  theme.palette.action.activatedOpacity
                ),
            }),
          }}
          className={classes.tableHeading}
        >
          {numSelected > 0 ? (
            <Typography
              sx={{ flex: "1 1 100%" }}
              color="inherit"
              variant="subtitle1"
              component="div"
            >
              {numSelected} selected
            </Typography>
          ) : (
            <Typography
              sx={{ flex: "1 1 100%" }}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              Categories
            </Typography>
          )}

          <CategoriesSearchBox data={catitems} />

          {numSelected > 0 ? (
            <Tooltip title="Delete">
              <IconButton>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Filter list">
              <IconButton>
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          )}
          <AddCircleIcon
            onClick={() => {
              setopenModule("Add Category");
              authCtx.setOpenCategoryModal(true);
            }}
          />
        </Toolbar>
        <TableContainer>
          {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              rowCount={menu.length}
            />
            <TableBody>
              {menuData.map((menuData) => {
                return (
                  <>
                    <TableRow
                      className={classes.menuRow}
                      onDoubleClick={() => {
                        authCtx.setOpenCategoryModal(true);                     
                        setopenModule("Edit Your Category");
                        authCtx.setcategoryData({id: menuData.id, Name: menuData.MenuName, Visible: menuData.Visible, Type:"menu", Link: ""});
                          console.log(menuData);
                    
                      }}
                    >
                      <TableCell>Menu</TableCell>
                      <TableCell>{menuData.MenuName}</TableCell>
                      <TableCell></TableCell>
                      <TableCell>1</TableCell>
                      <TableCell>{menuData.Visible && <CheckIcon />}</TableCell>
                    </TableRow>
                     {itemData
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((items, index1) => {
                        if (items.MenuCartID === menuData.id) {
                          return (
                            <TableRow
                              className={classes.itemRow}
                              onDoubleClick={() => {
                                authCtx.setOpenCategoryModal(true);
                                setopenModule("Edit Your Category");
                                authCtx.setcategoryData({id: items.id, Name: items.Name, Visible: items.Visible, Type:"item", Link: items.MenuCartID});
                                console.log(items)
                              }}
                            >
                              <TableCell>Item</TableCell>
                              <TableCell>{items.Name}</TableCell>
                              <TableCell>{menuData.MenuName}</TableCell>
                              <TableCell>2</TableCell>

                              <TableCell>
                                {items.Visible && <CheckIcon />}
                              </TableCell>
                            </TableRow>
                          );
                        } else {
                          return <></>;
                        }
                      })} 
                  </>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={menuData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <CategoryModal handleClose={handleClose} lable={openModule} menuData={menuData} />
    <button on onClick={()=> console.log(authCtx.categoryData)}>bvcgdhb</button>
    </div>
  );
};

export default MenuGroup;
