import React, { useState } from 'react';
import classes from './Store.module.css';
import Card from '../../../Common/UI/Card/Card';
import StoreMallDirectoryIcon from '@mui/icons-material/StoreMallDirectory';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

import {BiSolidDownArrow} from 'react-icons/bi'
import {BiSolidUpArrow} from 'react-icons/bi'

import InputLabel from '@mui/material/InputLabel';
 
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';



const storeType = [
  {value: 'Restaurant', label: 'Restaurant', }, {value: 'Bar', label: 'Bar',}, {value: 'Bistro', label: 'Bistro',} ,{value: 'Brasserie', label: 'Brasserie',}, 
  {value: 'Sandwich shop', label: 'Sandwich shop', },
  {value: 'Café', label: 'Café', }, {value: 'Cafeteria', label: 'Cafeteria', }, {value: 'Cocktail bar', label: 'Cocktail bar', }, 
  {value: 'Hotel', label: 'Hotel', }, {value: 'Ice cream parlor', label: 'Ice cream parlor', },
  {value: 'Food truck', label: 'Food truck', }, {value: 'Pizzeria', label: 'Pizzeria', }, {value: 'Popup', label: 'Popup', }, {value: 'Snackbar', label: 'Snackbar', }, 
  {value: 'Takeaway', label: 'Takeaway', },
  {value: 'Caterer', label: 'Caterer', }, {value: 'Bakery', label: 'Bakery', }, {value: 'Butcher shop', label: 'Butcher shop', }, {value: 'Tapas bar', label: 'Tapas bar', }, 
  {value: 'Lounge bar', label: 'Lounge bar', },
  {value: 'Sisha bar', label: 'Sisha bar', }, {value: 'Event', label: 'Event', }, {value: 'Brewery', label: 'Brewery', }, {value: 'Canteen', label: 'Canteen', }, 
  {value: 'Sports club', label: 'Sports club', },
  {value: 'Sports complex', label: 'Sports complex', },
];
const languages = [
  {value: 'eng', label: 'English', }, {value: 'arb', label: 'Arabic',},
];

const storeName = [
  {value: 'kababji', label: 'kababji', }, {value: 'zaatar', label: 'zaatar w zeit',},  {value: 'falamanki', label: 'falamanki',}, {value: 'em sherif', label: 'Em Sherif',},
];

const paymentMethod = [ 
  {value: 'Cash', label: 'Cash', }, {value: 'Contactless payment', label: 'Contactless payment',}, {value: 'MasterCard', label: 'MasterCard',} ,{value: 'Visa', label: 'Visa',},
];
const currencies = [
  {value: 'USD', label: 'USD ($)', }, {value: 'leb', label: 'Lebanese pound (ل.ل)',}, {value: 'BTC', label: '฿',} ,{value: 'JPY', label: '¥',},
];

export default function StoreInformations() {
  

const [type, settype] = useState('');

  const handleChange = (event) => {
    settype(event.target.value);
  };

  const [name, setname] =  useState('');

  const handleChange1 = (event) => {
    setname(event.target.value);
  };

  const [language, setlanguage] = useState('');

  const handleChange2 = (event) => {
    setlanguage(event.target.value);
  };

  
  const [currency, setcurrency] =  useState('');

  const handleChange3 = (event) => {
    setcurrency(event.target.value);
  };


  const [payment, setpayment] =  useState('');

  const handleChange4 = (event) => {
    setpayment(event.target.value);
  };

  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(prev => !prev);
  };


  return (
          <div style={{width:'90%'}}>
 
            <div className={classes.heading}>  <h3> Store  </h3><button className={classes.minimizewbtn} onClick={toggleVisibility}>
          {isVisible ?    <BiSolidDownArrow/> :<BiSolidUpArrow/> }
        </button></div>


         <Card className={classes.card1}>
         
     
        {!isVisible && (
          <div className={classes.inputs_container}>


          <FormControl sx={{ m: 1, minWidth: 120 }}    >
            
        <Select      style={{borderRadius:'116px' , border: '2px solid #37A190' , height:'60px'   }}   
          value={type}
          onChange={handleChange}
          displayEmpty
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <em>Store Type</em>;
            }

            return type;
          }}
          inputProps={{ 'aria-label': 'Without label' }}
        >   
            {storeType.map((store) => (
          <MenuItem key={store.value} value={store.value}>
            {store.label}
            
          </MenuItem>
        ))}
        </Select>
      </FormControl >


      <FormControl sx={{ m: 1, minWidth: 120 }}    >
        <Select      style={{borderRadius:'116px' , border: '2px solid #37A190' , height:'60px'}}   
          value={name}
          onChange={handleChange1}
          displayEmpty
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <em>Store Name</em>;
            }

            return name;
          }}
          inputProps={{ 'aria-label': 'Without label' }}
        >   
            {storeName.map((store) => (
          <MenuItem key={store.value} value={store.value}>
            {store.label}
            
          </MenuItem>
        ))}
        </Select>
      </FormControl >


      <FormControl sx={{ m: 1, minWidth: 120 }}    >
        <Select      style={{borderRadius:'116px' , border: '2px solid #37A190' , height:'60px'}}   
          value={language}
          onChange={handleChange2}
          displayEmpty
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <em>Language</em>;
            }

            return language;
          }}
          inputProps={{ 'aria-label': 'Without label' }}
        >   
            {languages.map((store) => (
          <MenuItem key={store.value} value={store.value}>
            {store.label}
            
          </MenuItem>
        ))}
        </Select>
      </FormControl >



      <FormControl sx={{ m: 1, minWidth: 120 }}    >
        <Select      style={{borderRadius:'116px' , border: '2px solid #37A190' , height:'60px'}}   
          value={currency}
          onChange={handleChange3}
          displayEmpty
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <em>Currency</em>;
            }

            return currency;
          }}
          inputProps={{ 'aria-label': 'Without label' }}
        >   
            {currencies.map((store) => (
          <MenuItem key={store.value} value={store.value}>
            {store.label}
            
          </MenuItem>
        ))}
        </Select>
      </FormControl >


      <FormControl sx={{ m: 1, minWidth: 120 }}    >
        <Select      style={{borderRadius:'116px' , border: '2px solid #37A190' , height:'60px'}}   
          value={payment}
          onChange={handleChange4}
          displayEmpty
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <em>Payment Method</em>;
            }

            return payment;
          }}
          inputProps={{ 'aria-label': 'Without label' }}
        >   
            {paymentMethod.map((store) => (
          <MenuItem key={store.value} value={store.value}>
            {store.label}
            
          </MenuItem>
        ))}
        </Select>
      </FormControl >

          </div> 
          
          )}  </Card></div>
  )
}
